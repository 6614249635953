import React, {useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";

const AddModelAct = ({user, handleClose}) => {
    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const api = new Api();
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    const handleActSubmit = (data) => {
        const params = {
            title: data.title,
            description: data.description
        }
        api.postAct(params, file).then((response) => handleClose())
    }

    return (
        <div id={"addmodelact_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Partager un modèle d'acte</h2>
            </div>

            <div id={"addmodelact_form"} className={"profil_block"}>
                <form onSubmit={handleSubmit(handleActSubmit)}>
                <div className={"form_row_wrapper type2"}>
                        <label>Titre</label>
                        <input type={"text"} name={"title"} id={"title"} placeholder={"Titre"} {...register("title", {required: "Champs obligatoire"})}/>
                        <div className={"error"}>{errors.title && errors.title.message}</div>
                    </div>
                    <div className={"form_row_wrapper type2"}>
                        <label>Description</label>
                        <textarea name={"description"} id={"description"} {...register("description", {required: "Champs obligatoire"})}></textarea>
                        <div className={"error"}>{errors.description && errors.description.message}</div>
                    </div>
                    <div className={"form_row_wrapper dd_upload"}>
                        <FileUploader handleChange={handleChange} name="file" types={["PDF","DOCX"]} required label={"Téléverser un modèle"}/>
                        <div className={"error"}>{errors.file && errors.file.message}</div>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"}>Publier</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default AddModelAct