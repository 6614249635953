import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import {CtaReturn} from "../../other/Cta";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import SingleSwitchItemDetails from "../_singleItem/SingleSwitchItemDetails";
import Api from "../../helper/api";

const SingleSwitch = () => {

    //state
    const [switchDuty, setSwitchDuty] = useState([])
    const [userSwitch, setUserSwitch] = useState(false)
    const [pendingMatchs, setPendingMatchs] = useState(0)
    const [acceptedMatchs, setAcceptedMatchs] = useState(0)
    const [showDelete, setShowDelete] = useState(false);
    const [showEnd, setShowEnd] = useState(false);

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    //loaders
    const {switchPromise} = useLoaderData()

    const revalidator = useRevalidator()
    const navigate = useNavigate()
    const api = new Api()

    //context
    const {user} = useOutletContext()

    useEffect(() => {
        switchPromise.then((switchDuty) => {

            let pendingMatchCount = 0
            let acceptedMatchCount = 0

            switchDuty.linkings?.forEach((l) => {

                if(l.step === 1)
                    pendingMatchCount++
                else if(l.step === 3)
                    acceptedMatchCount++
            })

            setPendingMatchs(pendingMatchCount)
            setAcceptedMatchs(acceptedMatchCount)

            //switch is current user switch
            setUserSwitch(user.id === switchDuty.user.id)
            setSwitchDuty(switchDuty)
        })
    })

    //set switch as ended
    const handleEndSwitch = () => {
        api.updateSwitch(switchDuty.id, {closed : true}).then(() => {
            revalidator.revalidate()
            setShowEnd(false)
        })
    }

    //delete switch
    const handleDeleteSwitch = () => {
        api.deleteSwitch(switchDuty.id).then(() => {
            setShowDelete(false)
            navigate("/echanges")
        })
    }

    const SwitchActionBlock = () => {
        return (
            <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                {!switchDuty.closed && <>
                    <button className={"cta blue full"} onClick={() => setShowEnd(true)}>Clôturer cet échange</button>
                    <button className={"cta full"} onClick={() => setShowDelete(true)}>Supprimer</button>
                </>}
                {switchDuty.closed && <button className={"cta transparent full"}>Cet échange est terminé</button>}
            </div>
        )
    }

    return(
        <div className={"single_item_wrapper"} id={"single_help_wrapper"}>

            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={switchPromise}>
                    <div className={"side-left d-mobile-none"}>
                        <div className={"single_item_header single_mission_header"}>
                            <div className={"cta_wrapper"}>
                                <CtaReturn/>
                                {switchDuty.urgent && <span className={"urgent_label"}>Urgent</span>}
                            </div>
                            <SingleSwitchItemDetails singleItem={switchDuty} detail={false} />
                        </div>
                        <SingleItemMessages type={"switch"} linkings={switchDuty.linkings}/>
                    </div>
                    <div className={"side-right d-mobile-none"}>
                        <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={switchDuty.id} type={"switch"}/>
                        <SwitchActionBlock />
                    </div>
                    <div className={"d-desktop-none"}>
                        <SingleItemHeader singleItem={switchDuty} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>
                        <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={switchDuty.id} type={"switch"}/>

                        <SwitchActionBlock />

                        <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                            <SingleSwitchItemDetails singleItem={switchDuty} detail={true}/>
                        </SlidingPanel>
                    </div>

                    <SlidingPanel show={showDelete} handleClose={() => setShowDelete(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Suppression</h2>
                                <p>Supprimer cet échange ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleDeleteSwitch()}>Supprimer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowDelete(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>
                    <SlidingPanel show={showEnd} handleClose={() => setShowEnd(false)}>
                        <div>
                            <div className={"bloc_title_info"}>
                                <h2>Échange terminé</h2>
                                <p>Terminer cet échange ?</p>
                            </div>
                            <div className={"text-center mb2 mt3"}>
                                <button className={"cta full blue"} onClick={() => handleEndSwitch()}>Terminer</button>
                                <button className={"cta full blue reverse"} onClick={() => setShowEnd(false)}>Annuler</button>
                            </div>
                        </div>
                    </SlidingPanel>
                    {/*{(switchDuty) =>{*/}

                    {/*    setSwitchDuty(switchDuty)*/}

                    {/*    if(user.id === switchDuty.user.id) {*/}
                    {/*        return(*/}
                    {/*            <>*/}
                    {/*                <SingleItemHeader singleItem={switchDuty} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>*/}
                    {/*                <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={switchDuty.id} type={"switch"}/>*/}

                    {/*                <div id={"single_switch_cta"} className={"single_item_cta_wrapper"}>*/}
                    {/*                    <button className={"cta blue full"}>Cet échange est terminé</button>*/}
                    {/*                    <button className={"cta full"}>Supprimer</button>*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*        )*/}
                    {/*    }else {*/}
                    {/*        return(*/}
                    {/*            <>*/}
                    {/*                <OtherSingleItemHeader singleItem={switchDuty}/>*/}
                    {/*                <div id={"single_switch_cta"} className={"single_item_cta_wrapper"}>*/}
                    {/*                    <button className={"cta blue full"}>Candidater</button>*/}
                    {/*                </div>*/}
                    {/*            </>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*}}*/}
                </Await>
            </Suspense>
        </div>
    )
}

export default SingleSwitch