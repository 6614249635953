import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";
import {getValue} from "@testing-library/user-event/dist/utils";

const OptionScreenChoice = (props) => {

    //component state
    const [serverError, setServerError] = useState("")
    const navigate = useNavigate()

    //handle form
    const {register,formState: { errors }, handleSubmit, setValue, getValues} = useForm()

    const handleFormSubmit = async (data) => {

        const api = new Api()

        try{
            await api.UpdateUser(props.user.id, {
                mission : data.type.includes("mission"),
                skill : data.type.includes("skill"),
            })
            return navigate(0)
        }catch(error){
             setServerError(error.message)
        }

    }

    //checkboxgroup validation
    const handleCheckboxValidation = (data) => {
        if (Array.isArray(data) && data.length !== 0 ) {
            return true
        }

        return false;
    }

    const handleChange = (e) => {
        setValue('type', getValues('type')?.filter((obj) => { return obj !== 'community' }))
    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <div className={"choice-header-img"} />
                <h1>Que souhaitez-vous ?</h1>
                <p>
                    Choix multiple possible<br/>
                    Accès gratuit à la communauté inclus pour les 2 profils je propose des missions et/ou mes compétences.<br/>
                    Vous pouvez aussi accéder uniquement à la communauté.
                </p>

                <form id={"type_registration_form"} onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className={"form_row_wrapper type1"}>
                        <div className={"checkbox_wrapper checkbox_cta"}>
                            <input type={"checkbox"} name={"type"} id={"type_mission"} value={"mission"} {...register("type",{validate : handleCheckboxValidation, onChange: handleChange})} />
                            <label htmlFor={"type_mission"}>Je propose des missions</label>
                        </div>
                    </div>
                    <div className={"form_row_wrapper type1"}>
                        <div className={"checkbox_wrapper checkbox_cta"}>
                            <input type={"checkbox"} name={"type"} id={"type_skill"} value={"skill"} {...register("type",{validate : handleCheckboxValidation, onChange: handleChange})} />
                            <label htmlFor={"type_skill"}>Je propose mes compétences</label>
                        </div>
                    </div>
                    <div className={"form_row_wrapper type1"}>
                        <div className={"checkbox_wrapper checkbox_cta"}>
                            <input type={"checkbox"} name={"type"} id={"type_community"} value={"community"} {...register("type",{validate : handleCheckboxValidation})} onChange={() => { setValue('type', ['community'])}} />
                            <label htmlFor={"type_community"}>J’accède uniquement à la communauté</label>
                        </div>
                    </div>
                    {/*<div className={"form_row_wrapper text-center"}>*/}
                    {/*    <div className={"jumbotron_title"}>+ Communauté = Accès gratuit et inclus.</div>*/}
                    {/*</div>*/}

                    {/*<div className={"jumbotron"}>*/}
                    {/*    <div className={"jumbotron_title"}>Communauté</div>*/}
                    {/*    <div className={"jumbotron_content"}>*/}
                    {/*        Accès gratuit et inclus.*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={"error text-center"}>{serverError}</div>

                    <div className={"error text-center"}>
                        {errors.type && "Veuillez sélectionner les actions que vous souhaitez"}
                    </div>

                    <div className={"form_row_wrapper type1"}>
                        <input className={"submit-big"} type={"submit"} value={"Commencer"}/>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default OptionScreenChoice