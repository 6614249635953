import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";

const AddNoteToHelp = ({helpId, handleClose}) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const api = new Api();
    const [confirm, setConfirm] = useState(false)

    //handle form submit
    const handleHelpSubmit = (data) => {
        api.createHelpLinking(
            helpId,
            data.message,
        ).then((data) => setConfirm(true))
    }

    return (
        <div id={"addnotetohelp_wrapper"}>
            <div className={"bloc_title_info"}>
                {!confirm && <h2>Ajoutez un message à votre proposition</h2>}
                {confirm && <h2>Proposition envoyée !</h2>}
            </div>

            {!confirm && <div id={"addnotetohelp_form"} className={"profil_block"}>
                <form onSubmit={handleSubmit(handleHelpSubmit)}>
                    <div className={"form_row_wrapper type3"}>
                        <label>Votre message</label>
                        <textarea name={"message"} id={"message"} {...register("message")}/>
                        <div className="error">{errors.type && errors.type.message}</div>
                    </div>

                    <div className={"cta_wrapper"}>
                        <button className={"cta blue full"}>Envoyer</button>
                    </div>
                </form>
            </div>}
            {confirm && <div id={"confirmhelp_cta_wrapper"} className={"cta_wrapper"}>
                <button className={"cta full blue"} onClick={handleClose}>Fermer</button>
            </div>}
        </div>
)
}

export default AddNoteToHelp