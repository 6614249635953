import React, {Suspense, useEffect, useState} from "react";
import {Await} from "react-router-dom";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";
import AddressAutoComplete from "../../form/addressAutoComplete";
import DatePicker from "react-datepicker";

const AddPermanenceRequest = ({handleClose}) => {
    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const [dataPromise, setDataPromise] = useState(null)
    const [dateFromPicker, setDateFromPicker] = useState("");
    const [dateToPicker, setDateToPicker] = useState("");
    const api = new Api();

    const occupations = [
        {name: 'Titulaire', id: 1},
        {name: 'Suppléant', id: 2}
    ]

    //fetch data from api
    useEffect(() => {

        const p = Promise.all([
            api.getSwitchTypesList()
        ])

        setDataPromise(p)
    },[])

    //handle form submit
    const handleSwitchSubmit = (data) => {
        // console.log(data, "POST DATA")
        let params = {
            description: data.description,
            occupation: data.occupation,
            type: data.type,
            urgent: data.urgent,
            location: data.location?.address,
            latitude: data.location?.latitude,
            longitude: data.location?.longitude
        }
        if (data.from) {
            params.from = data.from
        }
        if (data.to) {
            params.to = data.to
        }
        api.createSwitch(params).then((response) => handleClose())
    }

    if (dataPromise === null) {
        return
    }

    return (
        <div id={"addPermanencerequest_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Créer une demande d’échange de permanance</h2>
                <p>En quelques secondes, décrivez votre besoin.</p>
            </div>

            <div id={"addpermanencerequest_form"} className={"profil_block"}>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={dataPromise}>
                        {(value) => {
                            const [typesList, courtsList] = value

                            return (
                                <form onSubmit={handleSubmit(handleSwitchSubmit)}>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Type</label>
                                        <select name={"type"} id={"type"} defaultValue={""} {...register("type", {required: "Veuillez renseigner cette information"})}>
                                            <option value="" disabled={true}>Type</option>
                                            {typesList.map((c, index) => {
                                                return (<option value={c.id} key={index}>{c.name}</option>)
                                            })}
                                        </select>
                                        <div className="error">{errors.type && errors.type.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>En qualité de</label>
                                        <select name={"type"} id={"type"} defaultValue={""} {...register("type", {required: "Veuillez renseigner cette information"})}>
                                            <option value="" disabled={true}>Type</option>
                                            {occupations.map((c, index) => {
                                                return (<option value={c.id} key={index}>{c.name}</option>)
                                            })}
                                        </select>
                                        <div className="error">{errors.type && errors.type.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Lieu</label>
                                        <AddressAutoComplete name={"location"} id={"location"} register={register} setValue={(value) => setValue("location", value)}/>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Début</label>
                                        <input type={"hidden"} {...register("from")} name={"from"} id={"from"} {...register("from", {required: "Champs obligatoire"})} />

                                        <DatePicker
                                            showYearDropdown
                                            yearDropdownItemNumber={30}
                                            placeholderText="Début"
                                            dateFormat="dd/MM/yyyy H:mm"
                                            showTimeSelect={true}
                                            selected={dateFromPicker} onChange={(date) => {
                                            setDateFromPicker(date)
                                            setValue("from", date.toISOString())
                                        }}
                                        />

                                        <div className="error">{errors.from && errors.from.message}</div>
                                    </div>
                                    {/*<div className={"form_row_wrapper type3"}>*/}
                                    {/*    <label>Durée estimée</label>*/}
                                    {/*    <input type={"text"} name={"duration"} id={"duration"} placeholder={"Type here"}/>*/}
                                    {/*</div>*/}
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Fin</label>
                                        <input type={"hidden"} {...register("to")} name={"to"} id={"to"}/>

                                        <DatePicker
                                            showYearDropdown
                                            yearDropdownItemNumber={30}
                                            placeholderText="Fin"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            showTimeSelect={true}
                                            selected={dateToPicker} onChange={(date) => {
                                            setDateToPicker(date)
                                            setValue("to", date.toISOString())
                                        }}
                                        />

                                        <div className="error">{errors.to && errors.to.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Description</label>
                                        <textarea name={"description"} id={"description"} {...register('description', {required: "Veuillez renseigner cette information"})}></textarea>
                                        <div className={"error"}>{errors.description && errors.description.message}</div>
                                    </div>

                                    <div className={"form_row_wrapper"}>
                                        <div className={"checkbox_wrapper checkbox"}>
                                            <input type={"checkbox"} name={"urgent"} id={"urgent"} {...register("urgent")}/>
                                            <label htmlFor={"urgent"}>Échange urgent</label>
                                        </div>
                                    </div>

                                    <div className={"text-center cta_wrapper"}>
                                        <button className={"cta full blue"}>Publier</button>
                                        <button className={"cta full blue reverse"}>Enregistrer</button>
                                    </div>
                                </form>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>
        </div>
    )
}

export default AddPermanenceRequest