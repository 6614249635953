import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../other/Constant";

/**
 *
 * @param missions
 * @returns {{draft: *[], closed: *[], published: *[]}}
 */
export const sortMissionByStatus = (missions) => {

    let missions_currents = [];
    let missions_draft = [];
    let missions_closed = [];

    missions.forEach( m => {

        if(m.published && !m.closed)
            missions_currents.push(m)
        else if(m.published && m.closed)
            missions_closed.push(m)
        else
            missions_draft.push(m)
    })

    return {
        published : missions_currents,
        closed : missions_closed,
        draft : missions_draft
    }
}

export const sortMissionCandidatByStatus = (missions) => {

    let missions_interest = [];
    let missions_discuss = [];
    let missions_agreement = [];
    let missions_closed = [];

    missions.forEach( m => {

        console.log("match details", m)

        if(m.mission.closed)
            missions_closed.push(m)
        else{
            switch(m.step){
                case STEP_RECIPIENT_ACCEPTED : missions_interest.push(m); break;
                case STEP_SENDER_ACCEPTED : missions_discuss.push(m); break;
                case STEP_AGREEMENT : missions_agreement.push(m); break;
                case STEP_DONE : missions_closed.push(m); break;
            }
        }
    })

    return {
        interest : missions_interest,
        closed : missions_closed,
        discuss : missions_discuss,
        agreement : missions_agreement,
    }
}


export const filterDuplicateHelpInLinkings = (myHelps, helpLinkings = false) => {
    let allHelps = []

    if (myHelps !== undefined) {
        myHelps?.map(help => {
            if (help.linkings?.length) {
                const sortedHelpLinkings = help.linkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
                    return b.step - a.step
                })
                sortedHelpLinkings.map(linking => {
                    if (allHelps.filter(obj => obj.help.id === help.id).length === 0) {
                        allHelps.push({help: help, step: linking.step})
                    }
                })
            } else {
                allHelps.push({help: help, step: 0})
            }
        })
    }
    const sortedHelpLinkings = helpLinkings && helpLinkings.length > 0 ? helpLinkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
        return b.step - a.step
    }) : []
    sortedHelpLinkings?.map(linking => {
        if (allHelps.filter(obj => obj.help.id === linking.help.id).length === 0) {
            allHelps.push({ help: linking.help, step: linking.step})
        }
    })
    return allHelps.sort((a, b) => {
        return b.step - a.step
    })
}

export const filterDuplicateSwitchDutyInLinkings = (mySwitchDuties, switchDutyLinkings = false) => {
    let allHelps = []

    if (mySwitchDuties !== undefined) {
        mySwitchDuties?.map(switchDuty => {
            if (switchDuty.linkings?.length) {
                const sortedHelpLinkings = switchDuty.linkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
                    return b.step - a.step
                })
                sortedHelpLinkings.map(linking => {
                    if (allHelps.filter(obj => obj.switchDuty.id === switchDuty.id).length === 0) {
                        allHelps.push({switchDuty: switchDuty, step: linking.step})
                    }
                })
            } else {
                allHelps.push({switchDuty: switchDuty, step: 0})
            }
        })
    }
    const sortedHelpLinkings = switchDutyLinkings && switchDutyLinkings.length > 0 ? switchDutyLinkings?.filter(obj => obj.step === 1 || obj.step === 3 || obj.step === 5).sort((a, b) => {
        return b.step - a.step
    }) : []
    sortedHelpLinkings?.map(linking => {
        if (allHelps.filter(obj => obj.switchDuty.id === linking.switchDuty.id).length === 0) {
            allHelps.push({ switchDuty: linking.switchDuty, step: linking.step})
        }
    })
    return allHelps.sort((a, b) => {
        return b.step - a.step
    })
}