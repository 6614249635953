import React from "react";

const Legal = (props) => {

    return(
        <div id={"community_cgu_wrapper"}>
            <h1>Mentions légales</h1>
            <p></p>
            <h2>Éditeur</h2>
            <p>
                Le site esterLaw est produit et édité par la SAS EsterLaw SAS au capital de 5000 Euros dont le siège social est situé 78 avenue des Champs Elysées 75008 Paris enregistrée au Registre du Commerce et des Sociétés de Paris sous le numéro 984 397 554.
                <br/>
                <br/>
                N° SIREN : 848 506 861
                <br/>
                <br/>
                Le directeur de la publication du site est Madame Anne Gayat en qualité de Présidente de la SAS EsterLaw
                <br/>
                <br/>
                esterLaw est accessible par le réseau Internet à l’adresse https://www.esterlaw.io et par les services « Appstore » et « Playstore » sous la forme d’une application mobile destinée à être utilisée sur les équipements sous iOS et Android compatibles.
            </p>
            <h2>Hébergement?</h2>
            <p>
                Amazon Web Services (AWS Europe). Les données sont stockées sur des serveurs situés dans l’Union Européenne
            </p>
            <h2>Acceptation conditions générales?</h2>
            <p>
                Tout internaute qui visite le site est présumé connaître et accepter, sans réserve, l’ensemble des présentes clauses , du seul fait de sa connexion et utilisation. Il est également présumé avoir pris connaissance de la politique de confidentialité et des conditions de gestion des cookies.
                <br/>
                <br/>
                Si l’internaute ne les accepte pas, il est invité à cesser sa navigation et l’utilisation du site.
                <br/>
                <br/>
                L’éditeur peut à tout moment modifier les présentes, l’utilisateur est informé que la version en vigueur est celle qui est en ligne au moment de sa connexion, à l’une quelconque des pages du site ou des services proposés. L’utilisateur l’accepte et s’engage à en prendre connaissance lors de chaque visite et connexion.
            </p>
            <h2>Contenu du site</h2>
            <p>
                Le contenu du site et sa structure générale, textes, documents téléchargeables et les éléments graphiques, photographiques et vidéos, sons, logos, icônes, documents téléchargeables, sont la propriété exclusive de EsterLaw SAS ou utilisés par EsterLaw SAS s avec l’accord des titulaires de droits. Aucune représentation, reproduction ni utilisation de tout ou partie du site et son contenu, par quelque procédé que ce soit, sans l’autorisation préalable et écrite de EsterLaw SAS n’est autorisée. Le non-respect de cette interdiction constituerait une contrefaçon sanctionnée par les articles L335-2 et suivants du Code de la Propriété intellectuelle.
                <br/>
                <br/>
                Les photographies, images, enregistrements vidéo affichés sur le site sont soit libres de droits soit couverts par des licences autorisant leur reproduction et exploitation par l’éditeur.
                <br/>
                <br/>
                LIENS HYPERTEXTE
                <br/>
                <br/>
                Aucun lien hypertexte vers les pages du présent site ne sont permises sans autorisation express et préalable d’EsterLaw SAS.
                <br/>
                <br/>
                CRÉDITS PHOTOS
                <br/>
                <br/>
                Adobe Stock
            </p>

            <h2>Utilisation du site</h2>
            <p>
                Tout visiteur du site s’engage à :
                <br/>
                <br/>
                Utiliser le Site conformément aux Lois et réglementations en vigueur et loyalement.
                <br/>
                <br/>
                À fournir des renseignements vrais dans tous formulaires qu’il peut être amené à remplir (demande de contact, de devis, abonnement à une newsletter…).
                <br/>
                <br/>
                À ne pas usurper, en tout ou partie, l’identité, le titre ou la qualité d’un tiers.
                <br/>
                <br/>
                De manière générale, à ne réaliser aucun acte de nature à compromettre, altérer ou perturber, de quelque manière que ce soit, le fonctionnement normal du site.
            </p>
            <h2>Accès aux services d'Esterlaw</h2>
            <p>
                Nous vous invitons à prendre connaissance des conditions générales de service et de vente
            </p>
            <h2>Données personnelles</h2>
            <p>
                Nous vous invitons à prendre connaissance de notre politique confidentialité politique de confidentialité
            </p>
            <br/>
            <br/>
            <div className={"wta_wrapper"}>
                <a className={"cta full blue"} onClick={props.handleClose}>Fermer</a>
            </div>
        </div>
    )
}

export default Legal