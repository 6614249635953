import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import {QuestionBlock} from "./_QuestionBlock";
import AddQuestion from "../../slidingContent/question/AddQuestion";
import CommunityCategory from "../CommunityCategory";
import FilterSearchBar from "../../other/FilterSearchBar";
import {isMobile} from "react-device-detect";
import {CtaReturn} from "../../other/Cta";
import Api from "../../helper/api";
import AddQuestionAnswer from "../../slidingContent/question/AddQuestionAnswer";

const Questions = () => {

    const {questions} = useLoaderData()
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    return(
        <div id={"legal_issues_wrapper"} className={""}>
            {isMobile && <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>}
            {!isMobile && <CommunityCategory category={"questions"} />}

            <div className={"content_header"}>
                <FilterSearchBar searchBar={true} searchBarPlaceholder={"Recherche un sujet, une spécialité ..."}/>
                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Poser une question</button>
                </div>
            </div>


            <div id={"help_demands"} className={"content_block"}>
                <div className={"grid_block_wrapper generic_data_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={questions}>
                            {(items) => {
                                if (items.length > 0) {
                                    return (items.map((question, key) => <QuestionBlock {...question} key={`question_${question.id}`} />))
                                } else {
                                    return (<GenericEmptyItem title={"Aucune question pour le moment"}/>)
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            </div>
            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddQuestion handleClose={() => setShowSlidingPanel(false)} />
            </SlidingPanel>
        </div>
    )
}

export default Questions