import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../../helper/api";
import {AuthContext} from "../../userAuth/AuthContext";

import PhoneInput from "react-phone-input-2";
import {CtaReturn} from "../../other/Cta";

const Phone = () => {

    const [serverError, setServerError] = useState("")
    const [showPhone, setShowPhone] = useState(true)

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = async (data) => {
        const api = new Api()
        try{
            if (showPhone) {
                await api.postSMS({
                    phone: data.phone
                }).then((response) => {
                    setShowPhone(false)
                })
            } else {
                await api.verifyPhoneNumber(data.code)
            }
        } catch(error) {
            setServerError(error.message)
        }

    }


    return(
        <div id={"phone_wrapper"} className={"profile-wrapper"}>
            <CtaReturn link={"/profile/parametres"}/>
            <h1>Modification du numéro de téléphone</h1>
            <p>Renseignez votre nouveau numéro de téléphone et saisissez le code reçu par SMS</p>
            <div className={"sponsorship_box"}>
                <form id={"connection_form"} onSubmit={handleSubmit(handleFormSubmit)}>
                    {!showPhone && <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"code"}>Code de vérification</label>
                        <input type={"text"} name={"code"} id={"code"} placeholder={"Code"}  {...register("code", {required : "Veuillez renseigner le code de validation" })} />
                        <div className={"error"}>{errors.code && errors.code.message}</div>
                    </div>}
                    {showPhone && <div className={"form_row_wrapper type3"}>
                        <input type={"hidden"} name={"phone"} id={"phone"} {...register("phone", {required: "Veuillez renseigner votre numéro de téléphone"})}/>

                        <PhoneInput
                            country={'fr'}
                            onChange={phone => setValue("phone", "+" + phone)}
                            placeholder={"602030405"}
                        />
                        <label htmlFor={"phone"}>Téléphone</label>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>}
                    <div className={"error text-center"}>{serverError}</div>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"submit"} value={showPhone ? "Renvoyer" : "Vérifier"}/>
                    </div>
                </form>

                {!showPhone && <div className={"connection_link"}>
                    <Link className={"connection_small_link"} to={"#0"} onClick={() => setShowPhone(!showPhone)}>Vous n'avez pas reçu de code ? Renvoyer le code</Link>
                </div>}
                {showPhone && <div className={"connection_link"}>
                    <Link className={"connection_small_link"} to={"#0"} onClick={() => setShowPhone(!showPhone)}>Saisir code</Link>
                </div>}
            </div>
        </div>
    )
}

export default Phone