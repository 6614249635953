import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext, useRevalidator} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import {ActBlock} from "./_ActBlock";
import AddModelAct from "../../slidingContent/act/AddModelAct";
import CommunityCategory from "../CommunityCategory";
import {isMobile} from "react-device-detect";
import {CtaReturn} from "../../other/Cta";
import Api from "../../helper/api";
import FilterButtonBar from "../../other/FilterButtonBar";

const Acts = () => {

    const {acts} = useLoaderData()
    const {user} = useOutletContext();
    const [filterValue, setFilterValue] = useState(0);
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);
    const [actState, setActState] = useSetState()
    const revalidator = useRevalidator()
    const [showDelete, setShowDelete] = useState(false);
    const navigate = useNavigate()
    const api = new Api()
    const [currentAct, setCurrentAct] = useState(null);

    useEffect(() => {
        acts.then((acts) => setActState({acts}))

    }, [acts]);

    const handleDelete = () => {
        if (currentAct) {
            api.deleteAct(currentAct.id).then(() => {
                setCurrentAct(null)
                setShowDelete(false)
                navigate("/acts")
            })
        }
    }

    return(
        <div id={"acts_wrapper"}>
            {isMobile && <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>}
            {!isMobile && <CommunityCategory category={"acts"} />}


            <div className={"content_header"}>
                <FilterButtonBar filters={[
                    {value: "act", label: `Modèle des confrères`, count: actState.acts?.filter(obj => {return obj.user?.id !== user.id}).length},
                    {value: "user_act", label: `Mes modèles`, count: actState.acts?.filter(obj => {return obj.user?.id === user.id}).length},
                ]} setFilterValue={setFilterValue}/>

                <div className={"text-right"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Ajouter un modèle d'acte</button>
                </div>
            </div>

            {filterValue === 0 && <div id={"switch_current"} className={"filter_container"}>
                <div className={"bloc_title_info"}>
                    <p>Retrouvez ici tous les modèles d'actes de la communauté.</p>
                </div>

                <div className={"grid_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={acts}>
                            {(acts) => {
                                if (acts?.filter(obj => {return obj.user?.id !== user.id}).length > 0) {
                                    return (acts?.filter(obj => {return obj.user?.id !== user.id}).map((s, key) => <ActBlock {...s} key={`act_${s.id}`} />))
                                } else {
                                    return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            </div>}

            {filterValue === 1 && <div id={"switch_current"} className={"filter_container"}>
                <div className={"bloc_title_info"}>
                    <p>Retrouvez ici tous vos modèles d'actes.</p>
                </div>

                <div className={"grid_block_wrapper"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={acts}>
                            {(acts) => {
                                if (acts?.filter(obj => {return obj.user?.id === user.id}).length > 0) {
                                    return (acts?.filter(obj => {return obj.user?.id === user.id}).map((s, key) => <ActBlock {...s} key={`act_${s.id}`} deleteCallBack={() => {
                                        setCurrentAct(s)
                                        setShowDelete(true)
                                    }}/>))
                                } else {
                                    return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                }
                            }}
                        </Await>
                    </Suspense>
                </div>
            </div>}

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddModelAct user={user} handleClose={() => {
                    // revalidator.revalidate()
                    setShowSlidingPanel(false)
                    window.location.reload()
                }}/>
            </SlidingPanel>
            <SlidingPanel show={showDelete} handleClose={() => setShowDelete(false)}>
                <div>
                    <div className={"bloc_title_info"}>
                        <h2>Suppression</h2>
                        <p>Supprimer ce modèle d'acte ?</p>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => handleDelete()}>Supprimer</button>
                        <button className={"cta full blue reverse"} onClick={() => setShowDelete(false)}>Annuler</button>
                    </div>
                </div>
            </SlidingPanel>
        </div>
    )
}

export default Acts