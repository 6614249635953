import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "./userAuth/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import companyLogo from '../images/mobile/header_logo.png';
import {isMobile} from "react-device-detect";
import * as Constant from "./other/Constant";
import Api from "./helper/api";

const Header = (props) => {

    const {state: ContextState, logout, updateUserInGlobalContext} = useContext(AuthContext)
    const mobileMenuRef = useRef(null);

    const navigate = useNavigate()

    const toggleMobileMenu = () => {
        mobileMenuRef.current.classList.toggle("open");
    }

    const onChangeProfile = (type) => {
        const api = new Api()

        api.UpdateUser(ContextState.user.id, {
            profileType : type
        }).then((user) => {
            updateUserInGlobalContext(user)
            navigate('/dashboard')
        })
    }

    return(
        <header>
            {ContextState.isLoggedIn && ContextState.user.updatedAt && <div id={"burger_menu"} onClick={toggleMobileMenu}/>}
            {(!ContextState.isLoggedIn || !ContextState.user.verified || (!ContextState.user.mission && !ContextState.user.skill)) && isMobile && <div className={"logo"} />}
            {ContextState.isLoggedIn && ContextState.user.verified && (ContextState.user.mission || ContextState.user.skill) &&
                <>
                    <div className={"logo"}>
                        <Link to={"/dashboard"}><img src={companyLogo} /></Link>
                    </div>
                    {!isMobile && ContextState.user.job === 0 && ContextState.user.skill == 1 && ContextState.user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <Link to={"/missions?create"} className={"cta small blue"}>Publier une mission</Link>
                    }
                    {/*{ContextState.user.job === 0 && ContextState.user.mission == 0 && ContextState.user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&*/}
                    {/*    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => setShowEnableProfile(true)}>Activer le profil recruteur</Link>*/}
                    {/*}*/}
                    {/*{ContextState.user.job === 0 && ContextState.user.skill == 0 && ContextState.user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&*/}
                    {/*    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => setShowEnableProfile(true)}>Activer le profil talent</Link>*/}
                    {/*}*/}
                    <ul className={"header-inline-menu"}>
                        <li>
                            <Link to={"/"}>Tableau de bord</Link>
                        </li>
                        <li>
                            <Link to={"/matchs"}>Matchs</Link>
                        </li>
                        <li>
                            <Link to={"/missions"}>Missions</Link>
                        </li>
                        <li>
                            <Link to={"/aides"}>Communauté</Link>
                        </li>
                        <li>
                            <Link to={"/messagerie"}>Messagerie</Link>
                        </li>
                    </ul>
                    <Link id={"notification"} to={"/notification"} className={ContextState.newNotifications ? 'notification-new' : ''}/>
                    <ul className={"header-inline-menu d-mobile-none"}>
                        <li>
                            <Link to={"/profile"}>Afficher mon profil</Link>
                        </li>
                    </ul>
                    {!isMobile && ContextState.user.job === 0 && ContextState.user.mission == 1 && ContextState.user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                        <Link to={"#0"} className={"cta profile_switch"} onClick={() => onChangeProfile(Constant.PROFIL_TYPE_RECRUTEUR)}>Aller vers le profil "Je propose des missions"</Link>
                    }
                    {!isMobile && ContextState.user.job === 0 && ContextState.user.skill == 1 && ContextState.user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <Link to={"#0"} className={"cta profile_switch"} onClick={() => onChangeProfile(Constant.PROFIL_TYPE_CANDIDAT)}>Aller vers le profil "Je propose mes compétences"</Link>
                    }
                    {ContextState.user.updatedAt && ContextState.user.subscription && <div id={"profile"} onClick={() => navigate("/profile")}>
                        {props.user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+props.user.imageName}/> }
                    </div>}
                    {(!ContextState.user.updatedAt || !ContextState.user.subscription) && <Link to={"#0"} id={"logout"} onClick={logout}></Link>}

                </>
            }

            {ContextState.isLoggedIn && ContextState.user.verified && (ContextState.user.mission || ContextState.user.skill) && <div id={"mobile_menu_wrapper"} ref={mobileMenuRef}>
                <button id={"mobile_menu_close"} onClick={toggleMobileMenu}/>
                <nav>
                    <ul>
                        <li>
                            <Link to={"/dashboard"} onClick={toggleMobileMenu}>Dashboard</Link>
                        </li>
                        <li>
                            <Link to={"/missions"} onClick={toggleMobileMenu}>Missions</Link>
                        </li>
                        <li>
                            <Link to={"/communaute"} onClick={toggleMobileMenu}>Communauté</Link>
                        </li>
                        <li>
                            <Link to={"/messagerie"} onClick={toggleMobileMenu}>Messagerie</Link>
                        </li>
                    </ul>
                </nav>
            </div>}

        </header>
    )
}

Header.defaultProps = {
    user: {}
}

export default Header