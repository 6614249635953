import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../userAuth/AuthContext";
import {GetHelpDate, GetHelpTime} from "../../helper/other";
import userCircle from "../../../images/mobile/user-circle.png";
import userCircleOwner from "../../../images/mobile/user-circle-owner.png";

export const QuestionBlock = (props) => {

    const {state} = useContext(AuthContext)
    const navigate = useNavigate()

    const userIsOwner = props.user && props.user.id == state.user.id

    const getTitle = () => {
        if (props.anonymous === true) {
            if (props.user.skills?.length > 0) {
                const skill = props.user.skills[0]
                if (skill) {
                    return skill.area.name
                }
            }
            return 'Membre'
        }
        return props.user?.firstname + ' ' + props.user?.lastname
    }

    return (
        <div className={`question_block_item small ${userIsOwner ? "own" : ""}`} onClick={() => navigate("/questions/" + props.id)} >
            <div className={"question_header_title"}>
                <div className={"picture"}>
                    <img src={props.user?.imageName ? process.env.REACT_APP_API_URL+"/"+props.user.imageName : userIsOwner ? userCircleOwner : userCircle} />
                </div>
                <div className={"title"}>{getTitle()}</div>
                {/*<div className={"date"}>{new Date(props.date).toLocaleDateString("fr-FR")}</div>*/}
            </div>
            <div className={"description"}>{props.description}</div>
            <div className={"stat"}>
                <div>{props.likes.length}</div>
                <div>{props.answers.length}</div>
                {/*<div>*/}
                {/*    <span>Droit des entreprises</span>*/}
                {/*    <span className={"more"}>+1</span>*/}
                {/*</div>*/}
            </div>
        </div>
    )

}

QuestionBlock.defaultProps = {
    title: "-",
    description: "-",
    answers: [],
    createdOn: "1970-01-01 00:00:00",
}

export default QuestionBlock