import React from "react";
import ProfilDataBlock from "../../other/ProfilDataBlock";

/**
 * Block to display a single Item details
 * @param mission
 * @returns {JSX.Element}
 * @constructor
 */
const SingleSwitchItemDetails = ({singleItem, detail}) => {
    console.log(singleItem, 'SWITCH')

    return(
        <div id={"single_mission_details"} className={"single_item_details"}>
            <h2>{detail ? "Détails" : singleItem.type?.name}</h2>
            {singleItem.description &&
                <div className={"single_item_detail_block single_item_detail_block_description d-mobile-none"}>
                    {singleItem.description}
                </div>
            }
            <div className={"single_mission_details_inner"}>
                {singleItem.description &&
                    <div className={"single_item_detail_block d-desktop-none"}>
                        <h3>Description</h3>
                        <p>{singleItem.description}</p>
                    </div>
                }
                {singleItem.occupation &&
                    <div className={"single_item_detail_block"}>
                        <h3>En qualité de</h3>
                        <div>{singleItem.occupation === 2 ? 'Suppléant' : 'Titulaire'}</div>
                    </div>
                }
                {singleItem.location &&
                    <div className={"single_item_detail_block"}>
                        <h3>Lieu</h3>
                        <div>{singleItem.location}</div>
                    </div>
                }
                {singleItem.date &&
                    <div className={"single_item_detail_block"}>
                        <h3>Date</h3>
                        <div>{new Date(singleItem.date).toLocaleDateString("fr-FR")}</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default SingleSwitchItemDetails;