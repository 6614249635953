//PROFIL TYPE
export const PROFIL_TYPE_RECRUTEUR = 0
export const PROFIL_TYPE_CANDIDAT = 1

//LINKINGS STEP
export const STEP_PENDING = 0;             // creation du match grace a l’algo
export const STEP_RECIPIENT_ACCEPTED = 1;  // le candidat accepte le match (interêt)
export const STEP_RECIPIENT_REFUSED = 2;   // le candidat refuse le match
export const STEP_SENDER_ACCEPTED = 3;     // le recruteur accepte le match
export const STEP_SENDER_REFUSED = 4;      // le recruteur refuse le match
export const STEP_AGREEMENT = 5;           // Accord
export const STEP_DONE = 6;                // Match terminé


export const NotificationTypes = {
    TYPE_PROFILE_INCOMPLETE: 0,
    TYPE_MISSION_INCOMPLETE: 1,
    TYPE_HELP_INCOMPLETE: 2,
    TYPE_SWITCH_DUTY_INCOMPLETE: 3,
    TYPE_PROFILE_FILLRATE: 4,
    TYPE_MISSION_FILLRATE: 5,
    TYPE_HELP_FILLRATE: 6,
    TYPE_SWITCH_DUTY_FILLRATE: 7,
    TYPE_GENERAL_HELP: 8,
    TYPE_MISSION_LINKING_NEW: 9,
    TYPE_HELP_LINKING_NEW: 10,
    TYPE_SWITCH_DUTY_LINKING_NEW: 11,
    TYPE_MISSION_LINKING_REMINDER: 12,
    TYPE_HELP_LINKING_REMINDER: 13,
    TYPE_SWITCH_DUTY_LINKING_REMINDER: 14,
    TYPE_MISSION_LINKING_RECIPIENT_ACCEPTED: 15,
    TYPE_HELP_LINKING_RECIPIENT_ACCEPTED: 16,
    TYPE_SWITCH_DUTY_LINKING_RECIPIENT_ACCEPTED: 17,
    TYPE_MISSION_LINKING_RECIPIENT_ACCEPTED_REMINDER: 18,
    TYPE_HELP_LINKING_RECIPIENT_ACCEPTED_REMINDER: 19,
    TYPE_SWITCH_DUTY_LINKING_RECIPIENT_ACCEPTED_REMINDER: 20,
    TYPE_MISSION_LINKING_SENDER_ACCEPTED: 21,
    TYPE_HELP_LINKING_SENDER_ACCEPTED: 22,
    TYPE_SWITCH_DUTY_LINKING_SENDER_ACCEPTED: 23,
    TYPE_MISSION_LINKING_SENDER_DECLINED: 24,
    TYPE_HELP_LINKING_SENDER_DECLINED: 25,
    TYPE_SWITCH_DUTY_LINKING_SENDER_DECLINED: 26,
    TYPE_MESSAGE_NEW: 27,
    TYPE_VIDEO_NEW: 28,
    TYPE_MISSION_FINISHED: 29,
    TYPE_HELP_FINISHED: 30,
    TYPE_SWITCH_DUTY_FINISHED: 31,
    TYPE_MISSION_FINISHED_REMINDER: 32,
    TYPE_HELP_FINISHED_REMINDER: 33,
    TYPE_SWITCH_DUTY_FINISHED_REMINDER: 34,
    TYPE_MISSION_UPDATED: 35,
    TYPE_HELP_UPDATED: 36,
    TYPE_SWITCH_DUTY_UPDATED: 37,
    TYPE_QUESTION_CREATED: 38,
    TYPE_QUESTION_LIKED: 39,
    TYPE_ANSWER_CREATED: 40,
    TYPE_ANSWER_LIKED: 41,
    TYPE_ACT_MODEL_CREATED: 42,
    TYPE_COMPANY_EMPLOYEE_REQUEST_NEW: 43,
    TYPE_COMPANY_EMPLOYEE_REQUEST_ACCEPTED: 44,
    TYPE_COMPANY_EMPLOYEE_REQUEST_DECLINED: 45,
    TYPE_PROFESSIONAL_CERTIFICATE_VERIFIED: 46,
    TYPE_PROFESSIONAL_CERTIFICATE_DECLINED: 47,
}
