import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../../helper/api";
import {AuthContext} from "../../userAuth/AuthContext";
import {CtaReturn} from "../../other/Cta";
import PasswordInput from "../../form/passwordInput";

const Password = () => {

    const [serverError, setServerError] = useState("")

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {
        setServerError("");

        if (data.password !== data.confirmationPassword) {
            setServerError("La confirmation de mot de passe est différente");
        } else {
            const api = new Api();
            const param = {
                password : data.password
            }
            api.updatePassword(param).then((res) => {
            }).catch(error => {
                setServerError(error.message);
            })
        }
    }

    return(
        <div id={"password_wrapper"} className={"profile-wrapper"}>
            <CtaReturn link={"/profile/parametres"}/>
            <h1>Modification de votre mot de passe</h1>
            <p>Renseignez votre nouveau mot de passe</p>
            <div className={"sponsorship_box"}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"password"}>Nouveau mot de passe</label>
                        <PasswordInput name={"password"} id={"password"} placeholder={"Nouveau mot de passe"} register={register}/>
                        <div className={"error"}>{errors.password && errors.password.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"confirmationPassword"}>Confirmation de mot de passe</label>
                        <PasswordInput name={"confirmationPassword"} id={"confirmationPassword"} placeholder={"Confirmation de mot de passe"} register={register} />
                        <div className={"error"}>{errors.confirmationPassword && errors.confirmationPassword.message}</div>
                    </div>

                    <div className={"error"}>
                        {serverError}
                    </div>

                    <div className={"cta_wrapper"}>
                        <input type={"submit"} className={"cta blue full"} value={"Valider"}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Password