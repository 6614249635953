import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import {CtaReturn} from "../../other/Cta";
import Api from "../../helper/api";
import AddQuestionAnswer from "../../slidingContent/question/AddQuestionAnswer";
import userCircle from "../../../images/mobile/user-circle.png";

const QuestionAnswerBlock = ({answer}) => {

    const getTitle = () => {
        if (answer.anonymous === true) {
            if (answer.user.skills?.length > 0) {
                const skill = answer.user.skills[0]
                if (skill) {
                    return skill.area.name
                }
            }
            return 'Membre'
        }
        return answer.user?.firstname + ' ' + answer.user?.lastname
    }

    return (
        <div className={"question_answer_item"}>
            <div className={"question_answer_header"}>
                <div className={"picture"}>
                    <img src={answer.user?.imageName ? process.env.REACT_APP_API_URL+"/"+answer.user.imageName : userCircle} />
                </div>
                <div className={"question_answer_header_title"}>
                    <div className={"title"}>{getTitle()}</div>
                    {answer.createdOn && <div className={"date"}>{new Date(answer.createdOn).toLocaleDateString("fr-FR")}</div>}
                </div>
            </div>
            <div className={"description"}>{answer.description}</div>
        </div>
    )
}

const QuestionHeader = ({user, question, handleLikeClick, handleCommentClick}) => {
    const getTitle = (question) => {
        if (question.anonymous === true) {
            if (question.user.skills?.length > 0) {
                const skill = question.user.skills[0]
                if (skill) {
                    return skill.area.name
                }
            }
            return 'Membre'
        }
        return question.user?.firstname + ' ' + question.user?.lastname
    }

    // console.log(question.likes, 'LIKES')
    return (
        <div className={"single_item_header single_question_header"}>
            <div className={"single_item_header_cta"}>
                <div className={"cta_wrapper"}>
                    <CtaReturn />
                </div>
                <div className={"single_item_header_actions"}>
                    <button className={`cta like ${question.likes.find(like => { return like.user?.id === user.id }) ? 'active' : ''}`} onClick={() => handleLikeClick(question)}/>
                    <button className={"cta comment"} onClick={handleCommentClick}/>
                </div>
            </div>
            <div className={"single_item_details"}>
                <div className={"single_question_header_title"}>
                    <div className={"picture"}>
                        <img src={question.user?.imageName ? process.env.REACT_APP_API_URL+"/"+question.user.imageName : userCircle} />
                    </div>
                    <h2>{getTitle(question)}</h2>
                </div>
                <div className={"stat"}>
                    <div>{question.likes.length}</div>
                    <div>{question.answers.length}</div>
                </div>
                <div className={"single_item_detail_block single_item_detail_block_description"}>{question.description}</div>
            </div>
        </div>
    )
}

const SingleQuestion = () => {

    const {questionPromise} = useLoaderData()
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [showDelete, setShowDelete] = useState(false);
    const {user} = useOutletContext()
    const [showSlidingPanelAnswer, setShowSlidingPanelAnswer] = useState(false);
    const navigate = useNavigate()
    const api = new Api()

    const handleLikeClick = (question) => {
        const data = {
            question: 'api/questions/' + question.id
        }
        api.postQuestionLike(data).then((response) => {
            window.location.reload()
        }).catch(error => {
            window.location.reload()
        })
    }

    const handleCommentClick = () => {
        setShowSlidingPanelAnswer(true)
    }

    const handleDelete = () => {
        api.deleteQuestion(currentQuestion.id).then(() => {
            setShowDelete(false)
            navigate("/questions")
        })
    }

    return (
        <div className={"single_item_wrapper single_item_wrapper_border"}>
            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={questionPromise}>
                    {(question) => {
                        setCurrentQuestion(question)

                        return (
                            <>
                                <div className={"side-left d-mobile-none"}>
                                    <QuestionHeader user={user} question={question} handleLikeClick={() => handleLikeClick(question)} handleCommentClick={() => handleCommentClick()}/>
                                    <div className={'single_item_messages'}>
                                        <h2>Réponses</h2>
                                        {question.answers &&
                                            question.answers.map((answer) => <QuestionAnswerBlock answer={answer}/>)
                                        }
                                        {question.answers.length === 0 &&
                                            <GenericEmptyItem title={"Aucune réponse pour le moment"}/>}
                                    </div>
                                </div>
                                <div className={"side-right d-mobile-none"}>
                                    <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                                        {question.user.id === user.id &&
                                            <button className={"cta full"} onClick={() => setShowDelete(true)}>Supprimer</button>}
                                    </div>
                                </div>
                                <div className={"d-desktop-none"}>
                                    <QuestionHeader user={user} question={question} handleLikeClick={() => handleLikeClick(question)} handleCommentClick={() => handleCommentClick()}/>

                                    <div className={'single_item_messages'}>
                                        <h2>Réponses</h2>
                                        {question.answers &&
                                            question.answers.map((answer) => <QuestionAnswerBlock answer={answer}/>)
                                        }
                                        {question.answers.length === 0 &&
                                            <GenericEmptyItem title={"Aucune réponse pour le moment"}/>}
                                    </div>
                                </div>
                            </>
                        )
                    }}
                </Await>
            </Suspense>
            <SlidingPanel show={showSlidingPanelAnswer} handleClose={() => setShowSlidingPanelAnswer(false)}>
                <AddQuestionAnswer question={currentQuestion} handleClose={() => {
                    window.location.reload()
                }} />
            </SlidingPanel>
            <SlidingPanel show={showDelete} handleClose={() => setShowDelete(false)}>
                <div>
                    <div className={"bloc_title_info"}>
                        <h2>Suppression</h2>
                        <p>Supprimer cette question ?</p>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => handleDelete()}>Supprimer</button>
                        <button className={"cta full blue reverse"} onClick={() => setShowDelete(false)}>Annuler</button>
                    </div>
                </div>
            </SlidingPanel>
        </div>
    )
}

export default SingleQuestion