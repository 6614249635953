import React, {useEffect, useState} from 'react';
import {useChat} from "../../other/Twilio";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    MessageSeparator,
    ConversationHeader,
    StarButton,
    VoiceCallButton,
    VideoCallButton,
    InfoButton,
    Button
} from '@chatscope/chat-ui-kit-react';
import Api from "../../helper/api";
import {CtaReturn} from "../../other/Cta";
import {set} from "react-hook-form";
import moment from "moment/moment";
import 'moment/locale/fr';
import {FaVideo} from "react-icons/fa";
import userCircle from '../../../images/mobile/user-circle.png'

const ConversationSingle = (props) => {

    const [currentConversation, setCurrentConversation] = useState(null)
    const [messages, setMessages] = useState(null)
    const [interlocutor, setInterlocutor] = useState('')
    const {conversations, chatClient, sendMessage} = useChat()
    const { messagerieId } = useParams()
    const [online, setOnline] = useState(false)
    const {user} = useOutletContext();
    const [linking, setLinking] = useState(null)
    const navigate = useNavigate()

    const api = new Api();

    const setup = () => {
        //set all message on read
        // currentConversation.setAllMessagesRead()

        api.getLinkingByConversation(currentConversation.sid).then((data) => {
            if (data && data.length) {
                setLinking(data[0])
                setInterlocutor(`${data[0].user.firstname}`)
                // setInterlocutor(`${data[0].user.firstname} ${data[0].user.lastname}`)
            }
        })

        if (chatClient.reachabilityEnabled) {
            currentConversation.getMembers().then(participants => {
                participants.forEach(async (participant) => {
                    const u = await participant.getUser();
                    if (u.identity != user?.uid && u.online) {
                        setOnline(true)
                    }
                });
            })
        }
    }

    useEffect(() => {
        if (currentConversation) {
            setup()
        }
    }, [currentConversation]);


    //load the first conversations
    const initConversation = () => {
        if (props.conv) { //DESKTOP
            const messages = props.conv.messages.items.map((message) => formatMessage(message))
            setMessages(messages)
            setCurrentConversation(props.conv)
        } else { //MOBILE
            conversations.forEach((conversation, key) => {
                if (conversation.sid === messagerieId) {
                    //format message to be displayed
                    const messages = conversation.messages.items.map((message) => formatMessage(message))
                    setMessages(messages)
                    setCurrentConversation(conversation)

                    return true;
                }
            })
        }
    }
    useEffect(initConversation, [props.conv]);

    //receive new message
    const handleNewMessage = (message) => {
        if (message.channel.sid === messagerieId || message.channel.sid === props.conv?.sid) {
            // Ajouter le nouveau message à la liste des messages actuels
            setMessages((prevMessages) => [...prevMessages, formatMessage(message)]);
            scrollConversationToBottom()
        }
    };

    //format message to be displayed in the chat component
    const formatMessage = (message) => {
        const hours = message.state.timestamp.getHours();
        const minutes = message.state.timestamp.getMinutes();

        const day = message.state.timestamp.getDate();
        const month = message.state.timestamp.getMonth() + 1;
        const year = message.state.timestamp.getFullYear();

        return({
            _id: message.sid,
            message: message.state.body,
            sentTime: message.dateCreated,
            sender : message.author,
            position : "single",
            direction : chatClient.user.identity === message.author ? "outgoing":"incoming",
            senttime : message.dateCreated ? moment(message.dateCreated).locale('fr').format('H:m') : '',
            sentdate : `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`,
            name : message.author.charAt(0)+message.author.charAt(1)
        })
    }

    //send message
    const handleOnSend = (html, textContent, innerText, nodeList) => {
        //console.log(html,textContent, innerText, nodeList)
        sendMessage(currentConversation.sid, innerText)
    }

    //handle visio chat starting
    const handleVideoChat = async () => {
        //create link and send it
        sendMessage(currentConversation.sid, `${user.firstname} invite ${interlocutor} : <a href='/messagerie/visio/${currentConversation.sid}'>Rejoindre la visio</a>`)
        navigate(`/messagerie/visio/${currentConversation.sid}`)
    }

    const scrollConversationToBottom = () => {
        if (document.getElementById("messageList")) {
            document.getElementById("messageList").scrollTop = document.getElementById("messageList").scrollHeight;
        }
    }

    //bind message event
    useEffect(() => {

        if (chatClient === null) {
            return;
        }

        // Abonner à l'événement de réception de message
        chatClient.on('messageAdded', handleNewMessage);

        // Nettoyer les abonnements à l'événement lors du démontage du composant
        return () => { chatClient.off('messageAdded', handleNewMessage); };

    }, [chatClient]);

    //scroll down on new message
    useEffect(() => {
        scrollConversationToBottom()
    }, [messages]);


    //TODO set all message on read on display

    return (
        <div id={"page_conversation_single"}>
            <div className="cta_wrapper">
                <CtaReturn />
            </div>
            {messages !== null &&
                <MainContainer>
                    <ChatContainer>
                        <ConversationHeader>
                            <ConversationHeader.Back />
                            <Avatar
                                name={interlocutor}
                                src={linking?.user?.imageName ? process.env.REACT_APP_API_URL+"/"+linking.user.imageName : userCircle}
                                status={online ? 'available' : 'invisible'}
                            />
                            <ConversationHeader.Content
                                info={online ? 'En ligne' : 'Hors ligne'}
                                userName={currentConversation?.friendlyName ? currentConversation?.friendlyName : interlocutor}
                            />
                            <ConversationHeader.Actions>
                                <button className={'cta full blue'} onClick={handleVideoChat}><Button title="Visio" onClick={handleVideoChat} icon={<FaVideo color={'#FFFFFF'}/>} /> Inviter à une nouvelle visio</button>
                            </ConversationHeader.Actions>
                        </ConversationHeader>

                        <MessageList id={"messageList"}>
                            {messages.map((message, key) => {
                                return(
                                    <>
                                        {/*Add date as separator if different from previous one*/}
                                        { (key === 0 || messages.at(key-1).sentdate !== message.sentdate ) &&
                                            <MessageSeparator>{message.sentdate}</MessageSeparator>
                                        }

                                        {/*<Message model={message} key={`message_${key}`} avatarSpacer={messages.at(key-1).sender === message.sender && message.direction === "incoming"}>*/}
                                        <Message model={message} key={`message_${key}`}>
                                            {/*display avatar only for first user message and only for incoming message*/}
                                            {/*{messages.at(key-1).sender !== message.sender && message.direction === "incoming" &&*/}
                                            {/*    <Avatar name={message.name} size={"md"}/>*/}
                                            {/*}*/}
                                            <Message.HtmlContent html={message.message}/>
                                            {/*<Message.Footer />*/}
                                            <Message.Footer>
                                                {message.senttime}
                                                {/*{message.dateCreated}*/}
                                            </Message.Footer>
                                        </Message>
                                    </>)
                                })
                            }
                        </MessageList>
                        <MessageInput placeholder="Type message here" onSend={handleOnSend}/>
                    </ChatContainer>
                </MainContainer>
            }
        </div>
    )

}

export default ConversationSingle