import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../../helper/api";
import {AuthContext} from "../../userAuth/AuthContext";

import {emailPattern} from "../../helper/formUtils";
import {CtaReturn} from "../../other/Cta";

const Email = () => {

    const [serverError, setServerError] = useState("")

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {
        const api = new Api();

        const param = {
            email : data.email
        }
        setServerError("");

        api.updateEmail(param).then((res) => {
        }).catch(error => {
            setServerError(error.message);
        })
    }

    return(
        <div id={"email_wrapper"} className={"profile-wrapper"}>
            <CtaReturn link={"/profile/parametres"}/>
            <h1>Modification de votre adresse email</h1>
            <p>Renseignez votre nouvelle adresse email</p>
            <div className={"sponsorship_box"}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"email"}>Email</label>
                        <input type={"email"} name={"email"} id={"email"} placeholder={"Adresse e-mail"} {...register("email", {
                            required: "Veuillez renseigner une adresse e-mail",
                            pattern: {
                                value: emailPattern,
                                message: "Veuillez saisir une adresse e-mail valide"
                            }
                        })}
                        />
                        <div className={"error"}>{errors.email && errors.email.message}</div>
                    </div>

                    <div className={"error"}>
                        {serverError}
                    </div>

                    <div className={"cta_wrapper"}>
                        <input type={"submit"} className={"cta blue full"} value={"Valider"}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Email