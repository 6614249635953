import React, {Suspense, useEffect, useState} from "react";
import {Await, useRevalidator} from "react-router-dom";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";
import {GenericEmptyItem} from "../../other/GenericBlock";
import AddressAutoComplete from "../../form/addressAutoComplete";
import DatePicker from "react-datepicker";

const AddHelpRequest = ({user, handleClose}) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const [dataPromise, setDataPromise] = useState(null)
    const [datePicker, setDatePicker] = useState("");
    const api = new Api();

    //fetch data from api
    useEffect(() => {

        const p = Promise.all([
            api.getHelpTypesList(),
            api.getCourtsList()
        ])

        setDataPromise(p)
    },[])

    //handle form submit
    const handleHelpSubmit = (data) => {
        let params = {
            title: data.title,
            description: data.description,
            type: data.type,
            urgent: data.urgent,
            court: data.court,
            location: data.location?.address,
            latitude: data.location?.latitude,
            longitude: data.location?.longitude,
            bar: data.bar
        }
        if (data.date) {
            params.date = data.date
        }
        api.createHelp(params).then((response) => {
            handleClose()
        })
    }

    if (dataPromise === null) {
        return
    }

    return (
        <div id={"addhelprequest_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Créer une demande d’aide</h2>
                <p>En quelques secondes, décrivez votre besoin.</p>
            </div>

            <div id={"addhelprequest_form"} className={"profil_block"}>

                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={dataPromise}>
                        {(value) => {
                            const [typesList, courtsList] = value

                            return (
                                <form onSubmit={handleSubmit(handleHelpSubmit)}>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Type</label>
                                        <select name={"type"} id={"type"} defaultValue={""} {...register("type", {required: "Veuillez renseigner cette information"})}>
                                            <option value="" disabled={true}>Type</option>
                                            {typesList.map((c, index) => {
                                                return (<option value={c.id} key={index}>{c.name}</option>)
                                            })}
                                        </select>
                                        <div className="error">{errors.type && errors.type.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Juridiction</label>
                                        <select name={"court"} id={"court"} defaultValue={""} {...register("court", {required: "Veuillez renseigner cette information"})}>
                                            <option value="" disabled={true}>Juridiction</option>
                                            {courtsList.map((c, index) => {
                                                return (<option value={c.id} key={index}>{c.name}</option>)
                                            })}
                                        </select>
                                        <div className="error">{errors.court && errors.court.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper type3"}>
                                        <label>Lieu</label>
                                        <AddressAutoComplete name={"location"} id={"location"} register={register} setValue={(value) => setValue("location", value)}/>
                                    </div>

                                    <div className={"form_row_wrapper type3"}>
                                        <label>Date</label>
                                        <input type={"hidden"} {...register("date")} name={"date"} id={"date"}/>

                                        <DatePicker
                                            showYearDropdown
                                            yearDropdownItemNumber={30}
                                            placeholderText="Date"
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            selected={datePicker} onChange={(date) => {
                                                setDatePicker(date)
                                                setValue("date", date.toISOString())
                                            }}
                                        />

                                        <div className="error">{errors.date && errors.date.message}</div>
                                    </div>

                                    <div className={"form_row_wrapper type3"}>
                                        <label>Titre</label>
                                        <input type={"text"} name={"title"} id={"title"} placeholder={"Titre"} {...register('title', {required: "Veuillez renseigner cette information"})}/>
                                        <div className={"error"}>{errors.title && errors.title.message}</div>
                                    </div>

                                    <div className={"form_row_wrapper type3"}>
                                        <label>Description</label>
                                        <textarea name={"description"} id={"description"} {...register('description', {required: "Veuillez renseigner cette information"})}></textarea>
                                        <div className={"error"}>{errors.description && errors.description.message}</div>
                                    </div>

                                    <div className={"form_row_wrapper"}>
                                        <div className={"checkbox_wrapper checkbox"}>
                                            <input type={"checkbox"} name={"urgent"} id={"urgent"} {...register("urgent")}/>
                                            <label htmlFor={"urgent"}>Aide urgente</label>
                                        </div>
                                    </div>

                                    <div className={"cta_wrapper"}>
                                        <button className={"cta full blue"}>Publier la demande d'aide</button>
                                        <button className={"cta full blue reverse"}>Enregistrer en brouillon</button>
                                    </div>
                                </form>
                            )
                        }}


                    </Await>
                </Suspense>


            </div>

        </div>
    )
}

export default AddHelpRequest