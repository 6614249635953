import Api from "../helper/api";
import {redirect, useLocation} from "react-router-dom";

/**
 *
 * @returns {Promise<{missions: ([]|*), unreadMatch: number, user: axios.AxiosResponse<*>}>}
 */
export const getUserStepStatus = async (context) => {

    const api = new Api();

    let isLoggedIn = true;
    let showOptionChoiceScreen = false;
    let showVerifiedPhoneScreen = false;

    //get user from context provider
    const user = await Api.getUser().catch(() => {
        isLoggedIn = false;
    });

    if(user !== undefined){
        //check if user set is preference using mission and skill flag, only for profileType 1 and only if phone has been verified
        showOptionChoiceScreen = user.verified && (typeof user.mission === 'undefined' || user.mission === null) && (typeof user.skill === 'undefined' || user.skill === null) && user.profileType === 1;
        //check if user validate phone number
        showVerifiedPhoneScreen = !user.verified;
    }

    return {
        isLoggedIn : isLoggedIn,
        showOptionChoiceScreen : showOptionChoiceScreen,
        showVerifiedPhoneScreen : showVerifiedPhoneScreen,
        user : user
    };

}
