import {GenericEmptyItem, SingleItemMessageBlock} from "../../other/GenericBlock";
import React, {useEffect, useState} from "react";
import {useChat} from "../../other/Twilio";
import {ConversationBlock} from "../message/Conversations";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

const SingleItemMessages = ({type, linkings}) => {

    const [currentConversations, setCurrentConversations] = useState(null)
    const {conversations} = useChat()
    const navigate = useNavigate()

    const typeValue = {
        help : {
            class : 'help',
            //matchLink : `/aides/${itemId}/matchs`
        },
        switch : {
            class : 'switch',
            //matchLink : `/echanges/${itemId}/matchs`
        },
        mission : {
            class : 'mission',
            //matchLink : `/missions/${itemId}/matchs`
        }
    }

    useEffect(() => {

        //get conversation uid for current linkings
        let convUid = []
        linkings?.forEach((l) => {
            if(l.uid)
                convUid = [...convUid, l.uid]
        })

        //get uid from linkings
        setCurrentConversations(conversations.filter((c) => convUid.includes(c.sid)))
    }, [conversations])

    return (
        <div class={'single_item_messages'}>
            <h2>Dernières discussions</h2>

            {currentConversations === null && <GenericEmptyItem title={"Chargement en cours"}/> }

            {currentConversations && currentConversations.length !== 0 &&
                currentConversations.map((conversation) => <ConversationBlock conversation={conversation} showTitle={false} onClick={() => {
                    // navigate(`/messagerie/${match.id}/${match.uid}`)
                    navigate("/messagerie/" + conversation.sid)
                }}/>)
            }

            {currentConversations && currentConversations.length === 0 && <GenericEmptyItem title={"Ici vos prochains échanges"}/>}
        </div>
    )
}

export default SingleItemMessages