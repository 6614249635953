import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import MultiSelect from "./multiSelect";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import {useSetState} from "react-use";
import {getItemFromArrayById} from "../helper/formUtils";
import {useDidUpdate} from "../helper/hook";

const FormActivity = ({user, onFormSubmit, onFormCancel, activity}) => {

    //react hook form
    const {register,formState: { errors }, trigger, handleSubmit, reset, getValues, setValue, watch, resetField} = useForm();

    //api
    const api = new Api();

    //handle multiselect change
    const [countries, setCountries] = useState([])

    //Form State
    const [formData, setFormData] = useSetState({
        industriesList : [],
        experienceYear : [],
        countryList : [],
        updateUserField : false
    })

    //OnComponentDidMount -> Fetch form Data
    const fetchFormData = useCallback(async () => {
        const industriesListData = await api.getIndustriesList();
        const experienceYear = await api.getExperienceYearList();
        const countryListData = await api.getCountryList();

        setFormData({
            industriesList : industriesListData,
            experienceYear : experienceYear,
            countryList : countryListData,
            updateUserField : true
        })
    })

    useLayoutEffect( () => {
        fetchFormData()
    }, [])

    //Set user or item to edit values
    useEffect(() => {

        if(!formData.updateUserField)
            return

        // set item data
        if(activity === null) {
            if (user) {
                setCountries(user.countries)
            }
        } else {
            setValue("industry", activity.industry.id)
            setCountries(activity.countries)
            setValue("description", activity.description)
            setValue("experience", activity.experience.id)
        }

        setFormData({
            updateUserField : false
        })

    },[formData.updateUserField])

    //form cancel
    const handleCancel = () => {
        resetField("industry")
        resetField("experience")
        resetField("description")
        setCountries([])
        onFormCancel()
    }

    //form submit
    const handleFormSubmit = async () => {

        const is_valid = await trigger()

        if(is_valid){
            const data = getValues()

            data.industry = getItemFromArrayById(data.industry, formData.industriesList)
            data.experience = getItemFromArrayById(data.experience, formData.experienceYear)
            data.countries = countries
            onFormSubmit(data)
        }

    }

    //update component on specialization prop change
    useDidUpdate(() => {
        setFormData({ updateUserField : true })
    },[activity])

    return(
        <div className={"form"}>

            <div className={"form_row_wrapper type3"}>
                <label>Quelle compétence souhaitez-vous ajouter ?</label>
                <select name={"industry"} id={"industry"} defaultValue={""} {...register("industry",{required : "Veuillez renseigner ce champ"})}>
                    <option value={""} disabled={true}>Sélectionner</option>
                    {formData.industriesList.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.industry && errors.industry.message}</div>
            </div>
            <div className={"form_row_wrapper type3"}>
                <label>Expérience</label>
                <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience",{
                    required : "Veuillez renseigner cette information"
                })}>
                    <option value="" disabled={true}>Expérience</option>
                    {formData.experienceYear.map((c,index) => {
                        return(<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.experience && errors.experience.message}</div>
            </div>
            <div className={"form_row_wrapper type3"}>
                <label>Description</label>
                <textarea name={"description"} {...register("description",{required : "Veuillez renseigner ce champ"})}></textarea>
                <div className="error">{errors.description && errors.description.message}</div>
            </div>
            <div className={"form_row_wrapper type3 multiselect"}>
                <label>International</label>
                <MultiSelect name={"expertise"} id={"expertise"} options={ formData.countryList.map(item => ({ label: item.name, value: item.id }))} isMulti={true} closeMenuOnSelect={false} defaultValues={countries} getValues={setCountries}/>
                <div className="error">{errors.description && errors.description.message}</div>
            </div>
            <div className={"cta_wrapper text-right"}>
                <button className={"cta full blue"} onClick={handleSubmit(handleFormSubmit)}>
                    {activity === null && <>Ajouter le secteur</>}
                    {activity !== null && <>Valider les modifications</>}
                </button>
                <a className={"cta full blue reverse"} onClick={handleCancel}>Annuler</a>
            </div>
        </div>
    )
}

FormActivity.defaultProps = {
    user: null,
    onFormSubmit : (data) => console.log("onFormSubmit is not defined -> ",data),
    handleCancel : () => console.log("handleCancel is not defined"),
    activity : null
}

export default FormActivity