import axios from "axios";
import history from "./history";

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
        Accept: "application/json"
    },
    'Content-Type': 'multipart/form-data'
});

client.interceptors.request.use(config => {
        const userInfo = JSON.parse(localStorage.getItem("user"))
        if (userInfo && config.url.indexOf('api/token/refresh') === -1) {
            // console.log(userInfo.token, 'TOKEN')
            config.headers['Authorization'] = `Bearer ${userInfo.token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

//response interceptor
client.interceptors.response.use(null, async error => {
    if (error.response) {
        //expired token
        let route = window.location.pathname.substring(1)

        // if (error.response.status === 401) {
        // if (error.response.status === 401 && error.response.config.url.indexOf('/account') !== -1) {
        if (error.response.status === 401 && error.response.config.url.indexOf('auth') === -1 && route.indexOf('connexion') === -1 && route.indexOf('inscription') === -1) {
            const userInfo = JSON.parse(localStorage.getItem("user"))

            if (userInfo !== null && userInfo.refreshToken) {
                let failedRequest = error.response.config;
                Api.getAccessUsingRefresh(userInfo.refreshToken).then(async tokenResponse => {
                    console.log(tokenResponse, 'tokenResponse')
                    localStorage.setItem("user", JSON.stringify({ token: tokenResponse.token, refreshToken: tokenResponse.refresh_token }));
                    client.defaults.headers.common['Authorization'] = tokenResponse.token
                    failedRequest.headers['Authorization'] = 'Bearer ' + tokenResponse.token;
                    const newResponse = await client.request(failedRequest);

                    return Promise.resolve(newResponse)
                }).catch(error => {
                    localStorage.clear()
                    window.location.href = "/connexion"
                })
            } else {
                localStorage.clear()
                // history.push("/connexion")
                window.location.href="/connexion"
            }
        } else if (error.response.status === 401 && error.response.config.url.indexOf('refresh') !== -1) {
            console.log('OUT REFRESH TOKEN RESPONSE')
            localStorage.clear()
            window.location.href="/connexion"
        }
    }
    // Do something with response error
    return Promise.reject(error);
});


export default class Api {

    init = () => {
        if (Api.client !== null) {
            return Api.client
        }

        if (Api.api_token === "") {
            Api.userInfo = JSON.parse(localStorage.getItem("user"))

            if (Api.userInfo !== null) {
                Api.api_token = Api.userInfo.token
            }
        }

        let headers = {
            Accept: "application/json",
        };

        if (Api.api_token) {
            headers.Authorization = `Bearer ${Api.api_token}`;
        }

        return Api.client;
    };

    static ErrorLog = (error) => {
        console.log(error)
    }

    static RemoveAuth = () => {
        // console.log("coucou")
        localStorage.clear()
        history.push("/connexion")
    }

    static sendRequest = (requestType, endPoint, params={}, config = {}) => {

        let data

        switch(requestType){
            case "get" :
                //format params if exist
                let formattedParams = []

                if(Object.keys(params).length > 0){
                    for (const [key, p] of Object.entries(params)) {

                        if( (Array.isArray(p) && p.length > 0) || ( !Array.isArray(p) && p !== undefined && p !== "" && p !== null))
                            formattedParams = [...formattedParams,Api.formatGetParams(p, key)]
                    }

                    if(formattedParams.length > 0) {
                        endPoint = endPoint + "?" + formattedParams.join('&')
                    }
                }

                data = client.get(endPoint, {...config})
            break;

            case "post" :
                data = client.post(endPoint, params instanceof FormData ? params : {...params}, {...config})
            break;

            case "patch" :
                data = client.patch(endPoint, params instanceof FormData ? params : {...params}, {...config})
            break;

            case "delete" :
                data = client.delete(endPoint, {...config})
                break;
        }

        if(data === undefined)
            return []
        else
            return data.then((data) => data.data)
    }

    /**
     * Format data to match api routes
     * @param keys [{value, route}]
     * @param params
     */
    static formatApiRoute = (keys, params) => {

        keys.forEach((key) => {

            let tempData

            //if params key exist
            if(params[key.value]){

                //params is an array
                if(params[key.value] instanceof Array){
                    tempData = []
                    //convert param object to api route
                    params[key.value].forEach((item) => {
                        tempData = [...tempData, key.route+item.id]
                    })
                }
                else if(typeof params[key.value] == "string" || typeof params[key.value] == 'number'){
                    tempData = key.route+params[key.value]
                }else{
                    console.log("nothing to do")
                    console.log(typeof params[key.value]);
                }

                params[key.value] = tempData
            }
        })

        return params
    }

    /**
     * Format simple or array param for get request
     * @param params
     * @param name
     * @returns {string}
     */
    static formatGetParams = (params, name) => {
        let formattedParams

        if(Array.isArray(params))
            formattedParams = encodeURI(name+"[]="+params.join('&'+name+'[]='))
        else
            formattedParams = name+"="+params

        return formattedParams
    }

    /**
     * get JWT
     * @param params (email, password)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    static getUserToken = async (params) => {
        return Api.sendRequest("post","/auth", params);
    }

    static setHeader = (token) => {
        client.defaults.headers.common['Authorization'] = token
    }

    static getAccessUsingRefresh = async (refreshToken) => {
        delete client.defaults.headers.common.Authorization
        return Api.sendRequest('post', "/api/token/refresh", {
            refresh_token: refreshToken
        });
    }


    /**
     * Get user
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    static getUser = async () => {
        return Api.sendRequest("get","/api/account");
    }

    static getUserTest = async () => {
        return client.get("/api/account", {
            transformRequest: (data, headers) => {
                delete headers.Authorization;
                return data;
            }
        });
    }

    /**
     * Create a user
     * @param params
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    createUser = async ( params ) => {
        return await Api.sendRequest("post","/api/users", params);
    }

    /**
     * Update user
     * @param {*} params 
     * @returns 
     */
    UpdateUser = async (id,params) => {

        //convert object to api route
        params = Api.formatApiRoute([
            {value : "bar", route : "api/bars/"},
            {value : "countries", route : "api/countries/"},
            {value : "languages", route : "api/languages/"},
            {value : "experience", route : "api/experiences/"},
            {value : "collaborationType", route : "api/collaboration_types/"},
            {value : "available", route : "api/availabilities/"},
            {value : "position", route : "api/positions/"},
        ], params)

        return Api.sendRequest("patch","/api/users/"+id,{...params})
    }

    /**
     * Set user avatar picture
     * @param file
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserAvatar = async (file) => {

        let formData = new FormData();
        formData.append("imageFile", file);

        return Api.sendRequest("post", "/api/picture", formData);
    }

    /**
     * Set user profile type
     * @param type
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserProfilType = async (id, type) => {
        return Api.UpdateUser(id, { profileType : type })
    }

    /**
     * Set user professionnal card
     * @param file
     * @returns {Promise<axios.AxiosResponse<any> | void>}
     */
    setUserProfessionnalCard = async (file) => {

        let formData = new FormData();
        formData.append("professionalCertificate", file);

        return Api.sendRequest("post", "/api/professional_certificate", formData);
    }

    deleteUserProfessionnalCard = async () => {
        return Api.sendRequest("delete", "/api/professional_certificate");
    }

    /**
     * Send reset password request
     * @param mail
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    userLostPassword = (mail) => {
        return Api.init().post("/forgot-password/", {email : mail}).catch(Api.ErrorLog);
    }

    /**
     * Reset user password
     * @param token
     * @param password
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    userResetPassword = (token, password) => {
        return Api.init().post("/forgot-password/" + token, {password : password}).catch(Api.ErrorLog);
    }

    /**
     * Create a user specialization ressource
     * @param {*} description 
     * @param {*} specialization
     * @param {*} experience
     * @returns 
     */
    createUserSpesialization = (description, specialization, experience) => {

        const params = Api.formatApiRoute([
            {value : "specialization", route : "api/specializations/"},
            {value : "experience", route : "api/experiences/"}
        ], {
            description : description,
            specialization : specialization,
            experience : experience
        })

        return Api.sendRequest("post","/api/user_specializations",{...params});
    }

    /**
     * update a user specialization ressource
     * @param {*} description
     * @param {*} specialization
     * @param {*} experience
     * @param {*} id
     * @returns
     */
    updateUserSpesialization = (description, specialization, experience, id) => {

        const params = Api.formatApiRoute([
            {value : "specialization", route : "api/specializations/"},
            {value : "experience", route : "api/experiences/"}
        ], {
            description : description,
            specialization : specialization,
            experience : experience
        })

        return Api.sendRequest("patch","/api/user_specializations/"+id,{...params});
    }

    /**
     * delete a user specialization ressource
     * @param {*} id
     * @returns
     */
    deleteUserSpesialization = (id) => {
        return Api.sendRequest("delete","/api/user_specializations/"+id);
    }

    /**
     * Create a user skill ressource
     * @param {*} description
     * @param {*} skill
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    createUserSkill = async (description, area, experience, countries, subareas) => {

        const params = Api.formatApiRoute([
            {value : "countries", route : "api/countries/"},
            {value : "experience", route : "api/experiences/"},
            {value : "area", route : "api/areas/"},
            {value : "subareas", route : "api/subareas/"},
        ],
        {
            description : description,
            area : area,
            experience : experience,
            countries : countries,
            subareas : subareas
        })


        return Api.sendRequest("post","/api/user_skills",{...params});
    }

    /**
     * Update a user skill ressource
     * @param {*} description
     * @param {*} skill
     * @param {*} experience
     * @param {*} countries
     * @param {*} id
     * @returns
     */
    updateUserSkill = async (description, skill, experience, countries, subareas, id) => {

        const params = Api.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "area", route : "api/areas/"},
                {value : "subareas", route : "api/subareas/"},
            ],
            {
                description : description,
                area : skill,
                experience : experience,
                countries : countries,
                subareas : subareas
            })


        return Api.sendRequest("patch","/api/user_skills/"+id,{...params});
    }

    /**
     * Delete a user skill ressource
     * @param {*} id
     * @returns
     */
    deleteUserSkill = async (id) => {
        return Api.sendRequest("delete","/api/user_skills/"+id);
    }

    /**
     * Create a user industry ressource
     * @param {*} description
     * @param {*} industry
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    createUserIndustry = async (description, industry, experience, countries) => {

        const params = Api.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],
            {
                description : description,
                industry : industry,
                experience : experience,
                countries : countries,
            })

        return Api.sendRequest("post","/api/user_industries",{...params});
    }

    /**
     * Update a user industry ressource
     * @param {*} description
     * @param {*} industry
     * @param {*} experience
     * @param {*} countries
     * @returns
     */
    updateUserIndustry = async (description, industry, experience, countries, id) => {

        const params = Api.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],
            {
                description : description,
                industry : industry,
                experience : experience,
                countries : countries,
            })

        return Api.sendRequest("patch","/api/user_industries/"+id,{...params});
    }

    /**
     * Delete a user industry ressource
     * @param {*} id
     * @returns
     */
    deleteUserIndustry = async (id) => {
        return Api.sendRequest("delete","/api/user_industries/"+id);
    }

    /**
     * Create a user job ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserJob = async (title, description) => {
        return Api.sendRequest("post","/api/jobs",{
            title : title,
            description : description,
        });
    }

    /**
     * Update a user job ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserJob = async (title, description, id) => {
        return Api.sendRequest("patch","/api/jobs/"+id,{
            title : title,
            description : description,
        });
    }

    /**
     * Delete a user job ressource
     * @param {*} id
     * @returns
     */
    deleteUserJob = async (id) => {
        return Api.sendRequest("delete","/api/jobs/"+id);
    }

    /**
     * Create a user education ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserEducation = async (title,description) => {

        return Api.sendRequest("post","/api/education",{
            title : title,
            description : description
        });
    }

    /**
     * Update a user education ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserEducation = async (title, description, id) => {

        return Api.sendRequest("patch","/api/education/"+id,{
            title : title,
            description : description
        });
    }

    /**
     * Delete a user education ressource
     * @param {*} id
     * @returns
     */
    deleteUserEducation = async (id) => {

        return Api.sendRequest("delete","/api/education/"+id);
    }

    /**
     * Create a user association ressource
     * @param {*} title
     * @param {*} description
     * @returns
     */
    createUserAssociation = async (title,description) => {

        return Api.sendRequest("post","/api/associations",{
            title : title,
            description : description,
        });
    }

    /**
     * Update a user association ressource
     * @param {*} title
     * @param {*} description
     * @param {*} id
     * @returns
     */
    updateUserAssociation = async (title, description, id) => {

        return Api.sendRequest("patch","/api/associations/"+id,{
            title : title,
            description : description,
        });
    }

    /**
     * Delete a user association ressource
     * @param {*} id
     * @returns
     */
    deleteUserAssociation = async (id) => {
        return Api.sendRequest("delete","/api/associations/"+id);
    }

    /**
     * Create a mission
     * @param mission
     * @param industries
     * @param skills
     * @param specializations
     * @returns {Promise<[]|any>}
     */
    createMission = async (mission, industries, skills, specializations) => {

        mission = Api.formatApiRoute([
                {value : "collaborationType", route : "api/collaboration_types/"},
                {value : "estimatedDuration", route : "api/durations/"},
                {value : "experience", route : "api/experiences/"},
                {value : "type", route : "api/mission_types/"},
                {value : "bar", route : "api/bars/"},
            ],mission)

        industries.forEach((item, key)=> {

            item = Api.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "industry", route : "api/industries/"},
            ],item)

            industries[key] = item
        })

        skills.forEach((item, key)=> {
            item = Api.formatApiRoute([
                {value : "area", route : "api/areas/"},
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "subareas", route : "api/subareas/"},
            ],item)

            skills[key] = item
        })

        specializations.forEach((item, key)=> {
            item = Api.formatApiRoute([
                {value : "countries", route : "api/countries/"},
                {value : "experience", route : "api/experiences/"},
                {value : "specialization", route : "api/specializations/"},
            ],item)

            specializations[key] = item
        })

        return Api.sendRequest("post","/api/missions",{...mission, skills: skills, industries: industries, specializations: specializations});
    }

    /**
     * Return a collection of skills
     * @returns 
     */
    getSkillsList = async () => {
        return Api.sendRequest("get","/api/skills?order[name]");
    }

    /**
     * Return a collection of specializations
     * @returns 
     */
    getSpesializationsList = async () => {
        // return Api.sendRequest("get","/api/specializations?order[name]");
        return Api.init().get("/api/specializations").catch(Api.ErrorLog);
    }

    /**
     * Return a collection of area / skills
     * @returns 
     */
    getAreasList = async () => {
        return Api.sendRequest("get","/api/areas?order[name]");
    }

    /**
     * Return a collection of subarea / subskills
     * @returns 
     */
    getSubAreasList = async () => {
        return Api.sendRequest("get","/api/subareas");
    }

    /**
     * Return a collection of specializations
     * @returns
     */
    getSpecializationsList = async () => {
        return Api.sendRequest("get","/api/specializations?order[name]");
    }

    /**
     * Return a collection of industries
     * @returns
     */
    getIndustriesList = async () => {
        return Api.sendRequest("get","/api/industries?order[name]");
    }

    /**
     * Return a collection of Experience Years
     * @returns
     */
    getExperienceYearList = async () => {
        return await Api.sendRequest("get","/api/experiences?order[name]");
    }

    /**
     * Return a collection of mission type
     * @returns
     */
    getMissionTypesList = async () => {
        return Api.sendRequest("get","/api/mission_types?order[name]");
    }

    /**
     * Return a collection of mission Collaborations type
     * @returns
     */
    getMissionCollaborationTypesList = async () => {
        return Api.sendRequest("get","/api/collaboration_types?order[name]");
    }

    /**
     * Return a collection of Bar
     * @returns
     */
    getBarList = async () => {
        return Api.sendRequest("get","/api/bars?order[name]");
    }

    /**
     * Return a collection of Position
     * @returns
     */
    getPositionList = async () => {
        return Api.sendRequest("get","/api/positions?order[name]");
    }

    /**
     * Return a collection of country
     * @returns
     */
    getCountryList = async () => {
        return await Api.sendRequest("get","/api/countries?order[name]");
    }

    /**
     * Return a collection of language
     * @returns
     */
    getLanguageList = async () => {
        return await Api.sendRequest("get","/api/languages?order[name]");
    }

    /**
     * Return a collection of duration
     * @returns
     */
    getDurationList = async () => {
        return Api.sendRequest("get","/api/durations");
    }

    /**
     * Return a collection of Mission for current user
     * @returns
     */
    getUserMissions = async (steps=null, published= null, closed= null) => {
        return Api.sendRequest("get","/api/missions",{
            step : steps,
            published : published,
            closed : closed
        });
    }

    /**
     * Return missions linking
     * @param missions
     * @param steps
     */
    getMissionLinkings = async (missions = null, steps = null) => {
        return Api.sendRequest("get","/api/linkings",{mission : missions??[], step : steps??[]});
    }

    /**
     * Return help linkings
     * @returns {Promise<[]|*>}
     */
    getHelpLinkings = async (helps = null, steps = null, mine = null) => {
        // return Api.sendRequest("get","/api/help_linkings");
        return Api.sendRequest("get","/api/help_linkings",{
            help : helps,
            step : steps,
            mine : mine
        });
    }

    /**
     * Return switch linking
     * @param help
     * @param steps
     */
    getSwitchLinkings = async (switchDuty = null, steps = null, mine = null) => {
        return Api.sendRequest("get","/api/switch_duty_linkings",{switchDuty : switchDuty, step : steps, mine: mine});
    }

    /**
     * Return a specific Mission
     * @returns
     */
    getMission = async (missionId) => {
        //const encoded_steps = encodeURIComponent(steps);
        return Api.sendRequest("get","/api/missions/"+missionId);
    }

    /**
     * Return a specific Help
     * @returns
     */
    getHelp = async (helpId) => {
        //const encoded_steps = encodeURIComponent(steps);
        return Api.sendRequest("get","/api/helps/"+helpId);
    }

    /**
     * Return a specific Switch
     * @returns
     */
    getSwitch = async (switchId) => {
        return Api.sendRequest("get","/api/switch_duties/"+switchId);
    }

    /**
     * Return all job ressousrce pour sign up
     * @returns
     */
    getJob = async () => {
        return [
            {name: 'Avocat', id: 0},
            {name: 'Entreprises (services juridiques, RH...)', id: 1},
            {name: 'Experts (professeurs, docteurs, consultants, DPO…)', id: 2}
        ];
    }

    /**
     * return a list of availability ressource
     * @returns
     */
    getAvailability = async () => {
        return Api.sendRequest("get","/api/availabilities");
    }

    /**
     * Retrieve a mission match ressource
     * @type {{}}
     */
    getMatch = async (matchId) => {
        return Api.sendRequest("get","/api/linkings/"+matchId);
    }

    /**
     * Retrieve a Help match ressource
     * @type {{}}
     */
    getHelpMatch = async (matchId) => {
        return Api.sendRequest("get","/api/help_linkings/"+matchId);
    }

    /**
     * Retrieve a Switch match ressource
     * @type {{}}
     */
    getSwitchMatch = async (matchId) => {
        return Api.sendRequest("get","/api/switch_duty_linkings/"+matchId);
    }

    /**
     * validate phone number
     * @type
     */
    verifyPhoneNumber = async (code) => {
        return Api.sendRequest("post","/api/verify",{
            "verificationCode": code
        });
    }

    /**
     * Get Radius List value
     * @type
     */
    getRadiusList = async () => {
        //return Api.sendRequest("post","/api/verify",);
        return [
            {id : 1, name : "France entière"},
            {id : 2, name : "Autre"}
        ]
    }

    /**
     * Return all user help
     * @returns {Promise<[]|*>}
     */
    getHelps = async (published = null, closed = null, urgent = null, users = null, steps = null) => {
        return Api.sendRequest("get","/api/helps",{
            published : published,
            closed : closed,
            urgent : urgent,
            user : users,
            step : steps,
        });
    }

    /**
     * Return all user Switch Duties
     * @returns {Promise<[]|*>}
     */
    getUserSwitchDuties = async (published = null, closed = null, urgent = null, users = null, steps = null) => {
        return Api.sendRequest("get","/api/switch_duties",{
            published : published,
            closed : closed,
            urgent : urgent,
            user : users,
            step : steps,
        });
    }

    /**
     * Return all the user notifications
     * @returns {Promise<[]|*>}
     */
    getUserNotifications = async () => {
        return Api.sendRequest("get","/api/notifications");
    }

    /**
     * set Match as favorite
     */
    setMatchBookmark = (matchId, userId) => {

        const params = Api.formatApiRoute([
            {value : "linking", route : "api/linkings/"},
            {value : "user", route : "api/users/"}
        ], {
            linking : matchId,
            user : userId
        })

        return Api.sendRequest("post","/api/linking_bookmarks",{
            "linking" : params.linking,
            "user" : params.user
        });

    }

    /**
     * Delete a match bookmark
     * @param matchId
     * @returns {[]|*}
     */
    deleteMatchBookmark = (matchId) => {
        return Api.sendRequest("delete","/api/linking_bookmarks/"+matchId);
    }

    /**
     * Patch mission match step
     * @param matchId
     * @param step
     * @returns {[]|*}
     */
    updateMatch = (matchId, step) => {
        return Api.sendRequest("patch","/api/linkings/"+matchId,{
            step : step
        });
    }

    /**
     * Return a collection of help type
     * @returns
     */
    getHelpTypesList = async () => {
        return Api.sendRequest("get","/api/help_types?order[name]");
    }

    /**
     * Return a collection of switch type
     * @returns
     */
    getSwitchTypesList = async () => {
        return Api.sendRequest("get","/api/switch_duty_types?order[name]");
    }

    /**
     * Return a collection of court
     * @returns
     */
    getCourtsList = async () => {
        return Api.sendRequest("get","/api/courts?order[name]");
    }


    /**
     * Create Help request
     * @param data
     * @returns {Promise<[]|*>}
     */
    createHelp = async (data) => {

        const params = Api.formatApiRoute([
                {value : "type", route : "api/help_types/"},
                {value : "court", route : "api/courts/"},
                {value : "bar", route : "api/bars/"},
            ],
            {
                type : data.type,
                court : data.court,
                bar : data.bar
            })
        data.type = params.type
        data.court = params.court
        data.bar = params.bar
        data.published = true

        return Api.sendRequest("post","/api/helps",data);
    }

    /**
     * Create Switch Duty request
     * @param data
     * @returns {Promise<[]|*>}
     */
    createSwitch = async (data) => {
        const params = Api.formatApiRoute([
                {value : "type", route : "api/switch_duty_types/"},
            ],
            {
                type : data.type,
            })
        data.type = params.type
        data.published = true

        return Api.sendRequest("post","/api/switch_duties",data);

    }

    /**
     * Patch a mission
     * @param missionId
     * @param params
     * @returns {[]|*}
     */
    updateMission = (missionId, params) => {
        return Api.sendRequest("patch","/api/missions/"+missionId,params);
    }

    /**
     * Delete a mission
     * @param missionId
     * @returns {[]|*}
     */
    deleteMission = (missionId) => {
        return Api.sendRequest("delete","/api/missions/"+missionId);
    }

    /**
     * Check if user using oath exist
     * @param appleId
     * @param googleId
     * @param linkedinId
     */
    checkIfExist = (appleId = "", googleId = "", linkedinId = "") => {
        return Api.sendRequest("post","/api/check-exist",{
            "googleId": googleId,
            "linkedinId": linkedinId,
            "appleId": appleId
        });
    }

    /**
     * get twilio token
     */
    getTwilioToken = () => {
        return Api.sendRequest("post","/api/chat/token");
    }

    /**
     * get Visio token
     */
    getVisioToken = (conversationId) => {
        return Api.sendRequest("post", "/api/video/token",{
            conversationId : conversationId
        })
    }

    /**
     * get subscription options list
     * @returns {[]|*}
     */
    getSubscriptionsListing = () => {
        return Api.sendRequest("get", "/api/subscriptions")
    }

    /**
     * get current subscription info
     */
    getSubscriptionInfo = () => {
        return Api.sendRequest("get", "/api/subscriptioninfo")
    }

    /**
     * cancel current subscription
     */
    cancelSubscription = () => {
        return Api.sendRequest("get", "/api/subscriptioncancel")
    }

    /**
     * pause current subscription
     */
    pauseSubscription = () => {
        return Api.sendRequest("post", "/api/subscriptionpause")
    }

    /**
     * resume current subscription
     */
    resumeSubscription = () => {
        return Api.sendRequest("post", "/api/subscriptionresume")
    }

    /**
     * get subscription code
     */
    checkCode = (data) => {
        return Api.sendRequest("post", "/api/subscriptionCode", data)
    }

    /**
     * get subscription stripe token for payment
     * @returns {[]|*}
     */
    postSubscription = (data) => {
        return Api.sendRequest("post", "/api/subscriptions",data)
    }

    /**
     * create free subscription
     * @returns {[]|*}
     */
    postSubscriptionFree = (data) => {
        return Api.sendRequest("post", "/api/subscriptionsfree",data)
    }

    /**
     * Create a sponsorship ressource
     * @param {*} params
     * @returns
     */
    createSponsorship = async (params) => {
        return Api.sendRequest("post","/api/sponsorships", params);
    }

    /**
     * Return a collection of Sponsorship for current user
     * @returns
     */
    getSponsorships = async () => {
        return Api.sendRequest("get","/api/sponsorships");
    }

    /**
     * create an help linking ressource
     * @param {*} help
     * @param {*} message
     * @returns
     */
    createHelpLinking = (help, message) => {

        let params = Api.formatApiRoute([
            {value : "help", route : "api/helps/"},
        ], {
            help : help
        })
        if (message && message != '') {
            params.message = message
        }

        return Api.sendRequest("post","/api/help_linkings",{...params});
    }

    /**
     * create an switch duty linking ressource
     * @param {*} switchDuty
     * @param {*} message
     * @returns
     */
    createSwitchLinking = (switchDuty, message) => {

        let params = Api.formatApiRoute([
            {value : "switchDuty", route : "api/switch_duties/"},
        ], {
            switchDuty : switchDuty
        })
        if (message && message != '') {
            params.message = message
        }

        return Api.sendRequest("post","/api/switch_duty_linkings",{...params});
    }

    /**
     * Return a collection of Question
     * @returns
     */
    getQuestions = async () => {
        return Api.sendRequest("get","/api/questions");
    }

    /**
     * Return a collection of Question
     * @returns
     */
    getQuestionTypes = async () => {
        return Api.sendRequest("get","/api/question_types?order[name]");
    }


    /**
     * Return a Question resource
     * @returns
     */
    getQuestion = async (id) => {
        return Api.sendRequest("get","/api/questions/" + id);
    }

    /**
     * Return a collection of QuestionAnswer
     * @returns
     */
    getQuestionAnswers = async () => {
        return Api.sendRequest("get","/api/question_answers");
    }

    /**
     * Return a collection of QuestionBookmarks
     * @returns
     */
    getQuestionBookmarks = async () => {
        return Api.sendRequest("get","/api/question_bookmarks");
    }

    deleteQuestionBookmark = async (id) => {
        return Api.sendRequest("delete","/api/question_bookmarks/" + id);
    }

    postQuestion = async (data) => {
        return Api.sendRequest("post","/api/questions", data);
    }

    postQuestionBookmark = async (data) => {
        return Api.sendRequest("post","/api/question_bookmarks", data);
    }

    postQuestionAnswer = async (data) => {
        return Api.sendRequest("post","/api/question_answers", data);
    }

    postQuestionLike = async (data) => {
        return Api.sendRequest("post","/api/question_likes", data);
    }

    postQuestionAnswerLike = async (data) => {
        return Api.sendRequest("post","/api/question_answer_likes", data);
    }

    /**
     * Return a collection of Act
     * @returns
     */
    getActs = async () => {
        return Api.sendRequest("get","/api/act_models");
    }

    postSMS = async (data) => {
        return Api.sendRequest("post","/api/sendSMS", data);
    }

    getCompanies = async (data) => {
        return Api.sendRequest("get","/api/companies", data);
    }

    postCompany = async (data) => {
        return Api.sendRequest("post","/api/companies", data);
    }

    postCompanyEmployee = async (data) => {
        return Api.sendRequest("post","/api/company_employees", data);
    }

    postCompanyInsee = async (data) => {
        return Api.sendRequest("post","/api/companies_insee", data);
    }

    deleteNotification = async (id) => {
        return Api.sendRequest("delete","/api/notifications/" + id);
    }

    patchNotification = async (id, data) => {
        return Api.sendRequest("patch","/api/notifications/" + id, data);
    }

    getLinkingByConversation = async (uid) => {
        return Api.sendRequest("get","/api/linkings",{uid : uid});
    }

    postAct = async (data, file) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("title", data.title);
        formData.append("description", data.description);

        return Api.sendRequest("post","/api/act_models", formData);
    }

    updateHelp = (id, params) => {
        return Api.sendRequest("patch","/api/helps/"+id, params);
    }

    deleteHelp = async (id) => {
        return Api.sendRequest("delete","/api/helps/" + id);
    }


    updateSwitch = (id, params) => {
        return Api.sendRequest("patch","/api/switch_duties/"+id, params);
    }

    deleteSwitch = async (id) => {
        return Api.sendRequest("delete","/api/switch_duties/" + id);
    }

    deleteQuestion = async (id) => {
        return Api.sendRequest("delete","/api/questions/" + id);
    }

    deleteAct = async (id) => {
        return Api.sendRequest("delete","/api/act_models/" + id);
    }

    updateHelpMatch = (matchId, step) => {
        return Api.sendRequest("patch","/api/help_linkings/"+matchId,{
            step : step
        });
    }

    updateSwitchMatch = (matchId, step) => {
        return Api.sendRequest("patch","/api/switch_duty_linkings/"+matchId,{
            step : step
        });
    }

    updateEmail = (data) => {
        return Api.sendRequest("post", '/api/email', data)
    }

    updatePassword = (data) => {
        return Api.sendRequest("post", '/api/password', data)
    }

    patchUserNotificationSetting = (data) => {
        return Api.sendRequest("patch", 'api/user_notification_settings', data)
    }
}