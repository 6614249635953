import Api from "../helper/api";
import {createContext, useContext, useEffect, useState} from "react";
import { Client } from "twilio-chat";

const TwilioContext = createContext();

export const TwilioProvider = ({ isLoggedIn, children }) => {

    const api = new Api()

    const [chatClient, setChatClient] = useState(null);
    const [conversations, setConversations] = useState([]);

    //on component mount
    useEffect(() => {
        if (isLoggedIn) {
            initTwilio()
        }
    }, [isLoggedIn]);

    useEffect(() => {
        fetchConversations()
    },[chatClient])

    const initTwilio = async () => {

        //get token from ester api
        const token = await api.getTwilioToken()

        //init twilio with token
        const client = await new Client(token.token);
        setChatClient(client)

        client.on("tokenAboutToExpire", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        client.on("tokenExpired", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        client.on("conversationAdded", (conversation) => {
            fetchConversations()
            // if (store.getState() && store.getState().conversations && store.getState().conversations.length) {
            //     console.log(conversation, 'NEW CONV !')
            //     const conv = TwilioService.getInstance().parseConversation(conversation)
            //     console.log(conv, 'NEW PARSED CONV !')
            //     console.log(store.getState().conversations.length, 'CURRENT NB CONV')
            //     store.dispatch({ type: 'ADD_CONVERSATION', conversation: conv })
            //
            //     conversation.getMessages().then(messagePaginator => {
            //         if (messagePaginator.items.length) {
            //             const message = this.processConversation(messagePaginator)
            //             if (message) {
            //                 conv.lastMsg = TwilioService.getInstance().parseMessage(message)
            //                 if (conv.lastReadMessageIndex < message.index) {
            //                     conv.unreadCount = message.index - conv.lastReadMessageIndex
            //                     store.dispatch({ type: 'UNREAD_MESSAGES', unreadMessages: true })
            //                 }
            //                 store.dispatch({ type: 'UPDATE_CONVERSATION', conversation: conv })
            //             }
            //         }
            //     })
            // }
        });
        client.on("messageAdded", (message) => {
            fetchConversations()
        });
    }

    const fetchConversations = () => {
        if (chatClient) {
            try {
                // console.log("LOAD NEW CONVERSATIONS !")
                //fetch conversations
                chatClient.getSubscribedChannels().then(async (paginator) => {
                    if (paginator && paginator.items.length > 0) {
                        const conversationsWithMessages = await Promise.all(
                            paginator.items.map(async (channel) => {
                                channel.messages = await channel.getMessages();
                                return channel
                            })
                        );
                        // console.log(conversationsWithMessages, "NEW CONVERSATIONS FOUND !")
                        setConversations(conversationsWithMessages);
                    }
                })
                //fetch messages
                // const conversationsWithMessages = await Promise.all(
                //     subscribedChannels.items.map(async (channel) => {
                //         channel.messages = await channel.getMessages();
                //         return channel
                //     })
                // );
                // setConversations(conversationsWithMessages);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        }
    };

    const sendMessage = async (channelId, message) => {
        try {
            const channel = await chatClient.getChannelBySid(channelId);
            await channel.sendMessage(message);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // const processConversation = (paginator) => {
    //     if (paginator.hasNextPage) {
    //         const nextPaginator = paginator.nextPage()
    //
    //         return processConversation(nextPaginator)
    //     }
    //     const message = paginator.items.pop()
    //     if (message) {
    //         return message
    //     }
    //     return null
    // }

    return(
        <TwilioContext.Provider value={{chatClient, conversations, sendMessage}}>
            {children}
        </TwilioContext.Provider>
    )

}
export const useChat = () => useContext(TwilioContext);
