import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {AuthContext} from "../../userAuth/AuthContext";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";
import {GetHelpDate, GetHelpTime} from "../../helper/other";
import ShowMoreText from "react-show-more-text";
import moment from "moment";
import {useSetState} from "react-use";

const SwitchBlock = (props) => {

    const navigate = useNavigate()
    const {state} = useContext(AuthContext)
    const userIsOwner = props.owner || (props.user && props.user.id == state.user.id)
    const [status, setStatus] = useSetState({
        status : "...",
        class: ''
    })

    useEffect(() => {
        let maxLinkingStep = 0
        if(props.linkings){
            props.linkings.forEach((l) => {
                if(l.step > maxLinkingStep)
                    maxLinkingStep = l.step
            })
        }

        switch(maxLinkingStep) {
            case STEP_PENDING :
                setStatus({status : "", class: ''})
                break;

            case STEP_RECIPIENT_ACCEPTED :
                setStatus({status : "Matching en attente", class: 'step-pending'})
                break;

            case STEP_SENDER_ACCEPTED :
                setStatus({status : "Mise en relation", class: 'step-accepted'})
                break;

            case STEP_AGREEMENT :
                setStatus({status : "Accord", class: 'step-agreement'})
                break;

            case STEP_DONE :
                setStatus({status : "Terminée", class: 'step-done'})
                break;
            default :
                setStatus({completion : "", status : ""})
        }

    },[])

    return(
        <div className={`switch_block_item small ${userIsOwner ? "own" : ""}`} onClick={() => {
            props.link ? navigate(props.link) : navigate(`/echanges/${props.id}`)
        }}>
            {/*{props.step && <div className={`step ${status.class}`}>{status.status}</div>}*/}
            <div className={'switch_block_item_inner'}>
                <div className={"icon_circle"} />
                <div className={"content"}>
                    <div className={"title"}>{props.from != null ? moment(props.from).format('DD/MM/YYYY') : ''}</div>
                    <div className={"description"}>
                        <span className={"type"}>{props.type?.name}</span>
                        {status && status.status !== "" && <span className={"state"}>{status.status}</span>}
                    </div>
                </div>
            </div>
            <span className={"date"}>{moment(props.createdOn).format('DD/MM/YYYY')}</span>
        </div>
    )
}

SwitchBlock.defaultProps = {
    switchId : 0,
    title : "-",
    description : "-",
    linkings : [],
    createdOn : "1970-01-01 00:00:00",
}

export const SwitchFullBlock = (props) => {

    const {state} = useContext(AuthContext)
    const userIsOwner = props.user && props.user.id == state.user.id

    const navigate = useNavigate()

    return (
        <div className={`switch_block_item full ${userIsOwner ? "own" : ""} ${props.urgent ? "urgent" : ""}`}>

            {props.urgent && <span className={"urgent_label"}>Urgent</span>}

            <div className={"switch_header"}>
                <div className={"icon_circle"} />
                {/*<ItemLabel code={props.type.code} type={props.type.name}/>*/}

                <div className={"content"}>
                    <div className={"date"}>{moment(props.createdOn).format('DD/MM/YYYY')}</div>
                    <div className={"title"}>{props.type.name}</div>
                </div>
            </div>

            <div className={"description"}>
                <ShowMoreText
                    lines={2}
                    more="voir plus"
                    less="voir moins"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    expanded={false}
                    width={380}
                    truncatedEndingComponent={"... "}
                >
                    {props.description}
                </ShowMoreText>
            </div>

            <div className={"switch_info"}>
                <div>
                    <h5>En qualite de</h5>
                    <span>{props.occupation === 2 ? 'Suppléant' : 'Titulaire'}</span>
                </div>
                <div>
                    <h5>Lieu</h5>
                    <span>{props.location}</span>
                </div>
                <div>
                    <h5>Date</h5>
                    <span>{props.from ? GetHelpDate(props.from) : ''}</span>
                </div>
                <div>
                    <h5>Heure</h5>
                    <span>{`${GetHelpTime(props.from)} jusqu'à ${GetHelpTime(props.to)}`}</span>
                </div>
            </div>

            {props.showActions && <div className={"switch_cta_wrapper"}>
                {!userIsOwner &&
                    <>
                        {props.handleConfirm && <button className={"cta blue full"} onClick={() => props.handleConfirm(props.id)}>Proposer un échange</button>}
                        {props.handleAddNote && <button className={"cta blue full reverse"} onClick={() => props.handleAddNote(props.id)}>Envoyer une note</button>}
                        {!props.handleConfirm && !props.handleAddNote && <button className={"cta full"}>Demande d'échange envoyée</button>}
                    </>
                }
                {userIsOwner &&
                    <>
                        <button className={"cta blue full reverse"} onClick={() => {
                            navigate(`/echanges/${props.id}`)
                        }}>Afficher mon échange
                        </button>
                    </>
                }
            </div>}
        </div>

    )

}

SwitchFullBlock.defaultProps = {
    switchId: 0,
    title: "-",
    description: "-",
    linkings: [],
    createdOn: "1970-01-01 00:00:00",
}

export default SwitchBlock