import React, {useEffect, useState} from 'react';
import {useChat} from "../../other/Twilio";
import {Link, useNavigate, useOutletContext, useSearchParams} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Api from "../../helper/api";
import SingleMatch from "../match/SingleMatch";
import ConversationSingle from "./ConversationSingle";
import {GenericEmptyItem} from "../../other/GenericBlock";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import Linkify from 'react-linkify';
import userCircle from '../../../images/mobile/user-circle.png'

export const ConversationBlock = ({conversation, linking, showTitle, onClick}) => {

    const navigate = useNavigate()
    const {user} = useOutletContext();

    // console.log(conversation.unreadCnt, 'COUNT')
    // console.log(conversation.unreadDot, 'DOT')
    // console.log(linking, 'LINKING FOUND')

    // const message = conversation.messages.items[conversation.messages.items.length - 1].state.body.replace(/\n+/g, ' ').substring(0, 50)
    let lastMessage = conversation.messages.items[conversation.messages.items.length - 1];
    if (lastMessage) {
        lastMessage = lastMessage.state.body.replace(/\n+/g, ' ')
        lastMessage = lastMessage.substring(0, 150)
    }

    const getSubtitle = () => {
        if (linking?.mission) {
            return `${linking?.mission.reference} - Mission`
        } else if (linking?.help) {
            return 'Aide confraternelle'
        } else if (linking?.switchDuty) {
            return 'Échange de permanence'
        } else {
            return 'Mission'
        }
    }

    return(
        <div className={"conversation_block_item"} key={conversation.sid} onClick={onClick}>
            <div className={"picture"}>
                <img src={user?.imageName ? process.env.REACT_APP_API_URL+"/"+user.imageName : userCircle} />
            </div>
            <div className={"content"}>
                {showTitle && <div className={"title"}>{conversation.friendlyName}</div>}
                {showTitle && <div className={"subtitle"}>{getSubtitle()}</div>}
                {conversation.messages.items.length > 0 && <div className={"last_message"}>
                    <div dangerouslySetInnerHTML={{__html: lastMessage}}></div>
                </div>}
            </div>
            {conversation.lastMessage?.dateCreated && <div className={"extra"}>
                <div className={"time"}>{formatDistance(new Date(conversation.lastMessage.dateCreated), new Date(), { locale: fr })}</div>
                {/*{conversation.unreadCnt > 0 && <span className={"dot"}>{conversation.unreadCnt}</span>}*/}
            </div>}
            {/*{!showTitle && <Link className={"conversation-link-see d-mobile-none"}>Voir</Link>}*/}
        </div>
    )
}

ConversationBlock.defaultProps = {
    conversation : null,
    showTitle : true
}

const Conversations = ({limit = 0}) => {
    const {conversations} = useChat()
    const navigate = useNavigate()
    const api = new Api();
    const [currentConv, setCurrentConv] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [linkings, setLinkings] = useState([])
    const [filteredConversations, setFilteredConversations] = useState([])

    let convs = conversations
    if (limit > 0) {
        convs = conversations.slice(0, limit)
    }

    useEffect(() => {
        if (searchParams.get("c") && !isMobile && convs.length > 0) {
            const c = filteredConversations.find(obj => { return obj.sid == searchParams.get("c")})
            setCurrentConv(c)
        } else if (!limit && !isMobile && convs.length > 0 && !currentConv) {
            setCurrentConv(filteredConversations[0])
        }
    }, [filteredConversations])

    useEffect(() => {
        const promiseMission = new Promise((resolve, reject) => {
            api.getMissionLinkings().then(linkings => {
                resolve(linkings)
            })
        });
        const promiseHelp = new Promise((resolve, reject) => {
            api.getHelpLinkings(null, null, null).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseHelpMe = new Promise((resolve, reject) => {
            api.getHelpLinkings(null, null, true).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseSwitchDuty = new Promise((resolve, reject) => {
            api.getSwitchLinkings(null, null, null).then(linkings => {
                resolve(linkings)
            })
        });
        const promiseSwitchDutyMe = new Promise((resolve, reject) => {
            api.getSwitchLinkings(null, null, true).then(linkings => {
                resolve(linkings)
            })
        });

        Promise.all([promiseMission, promiseHelp, promiseHelpMe, promiseSwitchDuty, promiseSwitchDutyMe]).then((values) => {
            // console.log(values, 'TEST VALUES')
            // console.log(values[0], 'MISSIONS')
            setLinkings([...values[0], ...values[1], ...values[2], ...values[3], ...values[4]])
        });

        setFilteredConversations(convs.filter(function(conv){
            return linkings?.filter(function(linking){
                return linking.uid == conv.sid;
            }).length
        }))
    }, [conversations]);

    return (
        <div id={"page_conversation"}>
            <h2>Messagerie</h2>

            <div className={"conversation_wrapper"}>
                <div className={"side-right"}>
                    {!filteredConversations || filteredConversations.length === 0 && <>
                        <GenericEmptyItem title={"Aucune discussion pour le moment"} />
                    </>}
                    {filteredConversations.sort((a, b) => {
                        if (b.lastMessage && a.lastMessage){
                            return b.lastMessage.dateCreated.getTime() > a.lastMessage.dateCreated.getTime() ? 1 : -1
                        }
                        return b.dateUpdated?.getTime() > a.dateUpdated?.getTime() ? 1 : -1
                    }).map((conversation) => <ConversationBlock conversation={conversation} linking={linkings.find(obj => { return obj?.uid === conversation.sid })} onClick={() => {
                        if (isMobile) {
                            navigate("/messagerie/"+conversation.sid)
                        } else if (limit) {
                            navigate("/messagerie?c="+conversation.sid)
                        } else {
                            setCurrentConv(conversation)
                        }
                    }} /> )}
                </div>
                {!isMobile && limit == 0 && <div className={"side-left border-side-left"}>
                    {currentConv !== null && <ConversationSingle conv={currentConv} />}
                </div>}
            </div>
        </div>
    )

}

export default Conversations