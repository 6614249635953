import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../userAuth/AuthContext";
import {GetHelpDate, GetHelpTime} from "../../helper/other";
import userCircleOwner from "../../../images/mobile/user-circle-owner.png";
import userCircle from "../../../images/mobile/user-circle.png";

export const ActBlock = (props) => {

    const {state} = useContext(AuthContext)
    const navigate = useNavigate()

    console.log(props, 'ACTS')
    const userIsOwner = props.user && props.user.id == state.user.id

    return (
        <div className={"act_block_item"}>
            <div className={"cta_wrapper"}>
                {props.deleteCallBack != null && <button className={"cta delete"} onClick={(e) => {
                    e.preventDefault()
                    props.deleteCallBack()
                }}>delete</button> }
            </div>
            <div className={"question_header_title"}>
                <div className={"picture"}>
                    <img src={props.user?.imageName ? process.env.REACT_APP_API_URL+"/"+props.user.imageName : userIsOwner ? userCircleOwner : userCircle} />
                </div>
                <div className={"title"}>{props.title}</div>
            </div>
            <div className={"description"}>{props.description}</div>
            <div>
                <a href={`${process.env.REACT_APP_API_URL}/${props.fileName}`} target='_blank' className={"cta small blue"}>Afficher</a>
            </div>
            {/*<div className={"stat"}>*/}
            {/*    <div>59</div>*/}
                {/*<div>*/}
                {/*    <span>Droit des entreprises</span>*/}
                {/*    <span className={"more"}>+1</span>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    )

}

ActBlock.defaultProps = {
    title: "-",
    description: "-",
    createdOn: "1970-01-01 00:00:00",
    likes: [],
    filename: ""
}

export default ActBlock