import React, {Suspense, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {Await, useOutletContext} from "react-router-dom";

const AddQuestionAnswer = ({question, handleClose}) => {
    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const api = new Api();
    const {user} = useOutletContext();

    //handle form submit
    const handleAnswerSubmit = (data) => {
        const params = {
            question: 'api/questions/' + question.id,
            description: data.description,
            anonymous: data.anonymous
        }
        api.postQuestionAnswer(params).then((data) => handleClose())
    }

    return (
        <div id={"addquestion_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Ajouter une réponse</h2>
            </div>
            <div id={"addhelprequest_form"} className={"profil_block"}>
                <form onSubmit={handleSubmit(handleAnswerSubmit)}>
                    <div className={"form_row_wrapper type2"}>
                        <label>Description</label>
                        <textarea name={"description"} id={"description"} {...register("description", {required: "Champs obligatoire"})}></textarea>
                        <div className={"error"}>{errors.description && errors.description.message}</div>
                    </div>
                    <div className={"form_row_wrapper"}>
                        <div className={"checkbox_wrapper checkbox_blue"}>
                            <input type={"checkbox"} name={"anonymous"} id={"anonymous"} {...register("anonymous")}/>
                            <label htmlFor={"anonymous"}>Si vous souhaitez poster en anonyme, cocher cette case</label>
                        </div>
                    </div>

                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"}>Publier votre réponse</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddQuestionAnswer