import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

import Api from "../helper/api";
import {AuthContext} from "../userAuth/AuthContext";

import PasswordStrengthBar from 'react-password-strength-bar';
import {emailPattern} from "../helper/formUtils";
import PhoneInput from "react-phone-input-2";
import PasswordInput from "../form/passwordInput";
import SlidingPanel from "../protected_pages/_sliding_panel";
import Legal from "../other/Legal";
import DataPrivacy from "../other/DataPrivacy";
import CGSV from "../other/CGSV";

const Signup = () => {
    const [showLegal, setShowLegal] = useState(false)
    const [showDataPrivacy, setShowDataPrivacy] = useState(false)
    const [showCGV, setShowCGV] = useState(false)

    const [serverError, setServerError] = useState("")

    //user context
    const {login} = useContext(AuthContext);

    //API
    const api = new Api();
    const [formDataState, setFormDataState] = useSetState({
        jobList : []
    })

    //Load form data
    useEffect(() => {
        const fetchData = async () => {
            const jobs = await api.getJob()

            setFormDataState({
                jobList : jobs
            })
        }
        fetchData()
    }, [])

    //handle form
    const { register, formState: { errors }, watch, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {

        //profilType
        // 0 -> proposer mission
        // 1 -> proposer compétence

        //Default value
        const user = {
            "email" : data.mail,
            "password" : data.password,
            "job": parseInt(data.job),
            "profileType": 1,
            "lastname" : data.lastname,
            "firstname": data.firstname,
            "phone": data.phone,
            "mission" : null,
            "skill" : null,
        }

        //user is not a lawer
        if (data.job !== "0"){
            user.skill = true
            user.profileType = 0
        }

        createUserAccount(user)
    }

    /**
     * Finalize sign up journey
     */
    const createUserAccount = async (user) => {

        setServerError("");

        await api.createUser(user).then((res) => {

            //connect user and redirect to dashboard
            login(user.email, user.password).catch((error) => {
                setServerError("Un compte existe déjà avec cette adresse email.")
                // setServerError(error.response.data.message)
            });

        }).catch(error => {
            setServerError(error.message);
        })

    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <h1>S'inscrire</h1>
                <form id={"type_registration_form"} onSubmit={handleSubmit(handleFormSubmit)}>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"job"}>Je suis</label>
                        <select name="job" defaultValue={""} {...register("job", {required: "Veuillez renseigner cette information"})}>
                            <option value={""}>Je suis</option>
                            {formDataState.jobList.map((item) => {
                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                        <div className={"error"}>{errors.job && errors.job.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"firstname"}>Prénom</label>
                        <input type={"text"} name={"firstname"} id={"firstname"} placeholder={"Prénom"} {...register("firstname", {required: "Veuillez renseigner votre prénom"})}/>
                        <div className={"error"}>{errors.firstname && errors.firstname.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"name"}>Nom</label>
                        <input type={"text"} name={"lastname"} id={"lastname"} placeholder={"Nom"} {...register("lastname", {required: "Veuillez renseigner votre nom"})}/>
                        <div className={"error"}>{errors.lastname && errors.lastname.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <input type={"hidden"} name={"phone"} id={"phone"} {...register("phone", {required: "Veuillez renseigner votre numéro de téléphone"})}/>

                        <PhoneInput
                            country={'fr'}
                            onChange={phone => setValue("phone", "+" + phone)}
                            placeholder={"602030405"}
                        />
                        <label htmlFor={"phone"}>Téléphone</label>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"mail"}>Email</label>
                        <input type={"email"} name={"mail"} id={"mail"} placeholder={"Adresse e-mail"} {...register("mail", {
                            required: "Veuillez renseigner votre adresse e-mail",
                            pattern: {
                                value: emailPattern,
                                message: "Veuillez saisir une adresse e-mail valide"
                            }
                        })}
                        />
                        <div className={"error"}>{errors.mail && errors.mail.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"password"}>Mot de passe</label>
                        <PasswordInput name={"password"} id={"password"} placeholder={"Mot de passe"} register={register}/>
                        <div className={"error"}>{errors.password && errors.password.message}</div>
                    </div>

                    <PasswordStrengthBar shortScoreWord={"faible"} scoreWords={['faible', 'faible', 'moyen', 'fort', 'très fort']} className={"form_password_strength"} password={watch('password')}/>

                    <br/>
                    <p>Les informations sont vérifiées pour accéder aux services.</p>

                    <div className={"form_row_wrapper checkbox_wrapper"}>
                        <input type={"checkbox"}/>
                    </div>

                    <div className={"form_row_wrapper"}>
                        <div className={"checkbox_wrapper checkbox_blue"}>
                            <input type={"checkbox"} name={"cgv"} id={"cgv"} {...register("cgv",{required : "Champs obligatoire"})}/>
                            <label htmlFor={"cgv"}>J'accepte les <Link to={"#0"} onClick={() => setShowCGV(true)}>conditions générales de vente</Link></label>
                        </div>
                    </div>

                    <div className={"form_row_wrapper"}>
                        <div className={"checkbox_wrapper checkbox_blue"}>
                            <input type={"checkbox"} name={"politique_de_conf"} id={"politique_de_conf"} {...register("politique_de_conf",{required : "Champs obligatoire"})}/>
                            <label htmlFor={"politique_de_conf"}>J'ai pris connaissance de la <Link to={"#0"} onClick={() => setShowDataPrivacy(true)}>politique de confidentialité</Link> et des <Link to={"#0"} onClick={() => setShowLegal(true)}>mentions légales</Link></label>
                        </div>
                    </div>

                    <div className={"error"}>
                        {serverError}
                    </div>

                    <div className={"cta_wrapper"}>
                        <input type={"submit"} className={"cta blue full"} value={"S’inscrire gratuitement"}/>
                    </div>
                </form>

                <div className={"separator"}>
                    <div/>
                    <span>Ou</span>
                    <div/>
                </div>

                <div className={"connection_link"}>
                    Vous avez déjà un compte ? <Link className={"connection_small_link"} to={"/connexion"}>Se connecter</Link>
                </div>
            </div>

            <SlidingPanel show={showLegal} handleClose={() => setShowLegal(false)} forceSliding={true}>
                <Legal handleClose={() => setShowLegal(false)} />
            </SlidingPanel>
            <SlidingPanel show={showDataPrivacy} handleClose={() => setShowDataPrivacy(false)} forceSliding={true}>
                <DataPrivacy handleClose={() => setShowDataPrivacy(false)} />
            </SlidingPanel>
            <SlidingPanel show={showCGV} handleClose={() => setShowCGV(false)} forceSliding={true}>
                <CGSV handleClose={() => setShowCGV(false)} />
            </SlidingPanel>
        </div>
    )
}

export default Signup