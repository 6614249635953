//dummy missions
import Api from "../helper/api";
import {
    PROFIL_TYPE_CANDIDAT,
    PROFIL_TYPE_RECRUTEUR,
    STEP_AGREEMENT,
    STEP_PENDING,
    STEP_RECIPIENT_ACCEPTED,
    STEP_SENDER_ACCEPTED
} from "../other/Constant";

/**
 * Return all user missions list
 * @returns {{switchs: Promise<*>, missions: Promise<*>, helps: Promise<[]|*>}}
 */
export const getMatchs = (context) => {

    const api = new Api()
    let promises = {}

    if (context.state.user.profileType === PROFIL_TYPE_CANDIDAT) {
        const matchsMissionPromise = api.getMissionLinkings(null,[STEP_PENDING, STEP_SENDER_ACCEPTED, STEP_AGREEMENT])
        // const matchsMissionPromise = api.getMissionLinkings(null,[STEP_PENDING, STEP_SENDER_ACCEPTED])
        const matchsHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT], true)
        const matchsSwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT], true)

        promises = {
            missions : matchsMissionPromise,
            helps : matchsHelpPromise,
            switchs : matchsSwitchPromise
        }

    } else if (context.state.user.profileType === PROFIL_TYPE_RECRUTEUR) {
        const matchsMissionPromise = api.getMissionLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT])
        const matchsHelpPromise = api.getHelpLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT], true)
        const matchsSwitchPromise = api.getSwitchLinkings(null, [STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_AGREEMENT], true)

        promises = {
            missions : matchsMissionPromise,
            helps : matchsHelpPromise,
            switchs : matchsSwitchPromise
        }
    }

    return promises
}

/**
 * Return single Mission Match data
 * @param params - contain missionId
 */
export const getMissionMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getMatch(params.matchId)

    return {
        match : matchPromise
    }
}

/**
 * Return single Help Match data
 * @param params - contain missionId
 */
export const getHelpMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getHelpMatch(params.matchId)

    return {
        match : matchPromise
    }
}

/**
 * Return single Mission Match data
 * @param params - contain missionId
 */
export const getSwitchMatch = async ({params}) => {

    const api = new Api()
    const matchPromise = api.getSwitchMatch(params.matchId)

    return {
        match : matchPromise
    }
}