import React from "react";
import {Link} from "react-router-dom";

const CommunityCategory = ({category}) => {
    return(
        <div id={"community_category"}>
            <div className={"bloc_title_info"}>
                <h2>Bienvenue au sein de la communauté #esterLawyer</h2>
                <p>Cet espace est réservé aux avocats membres d'Esterlaw. Il est destiné à préserver et exprimer le lien de confraternité.</p>
            </div>
            <div className={"category_filters"}>
                <Link to={"/aides"} className={`cta full blue ${category === 'aides' ? '' : 'reverse'}`}>Aides confraternelles</Link>
                <Link to={"/echanges"} className={`cta full blue ${category === 'echanges' ? '' : 'reverse'}`}>Échanges de permanence</Link>
                <Link to={"/questions"} className={`cta full blue ${category === 'questions' ? '' : 'reverse'}`}>Questions juridiques</Link>
                <Link to={"/acts"} className={`cta full blue ${category === 'acts' ? '' : 'reverse'}`}>Modèles d'actes</Link>
            </div>
        </div>
    )
}

export default CommunityCategory