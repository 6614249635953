import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useSetState} from "react-use";
import {useDidUpdate} from "../helper/hook";

const FormEducation = ({user, onFormSubmit, onFormCancel, education}) => {

    //react hook form
    const {register,formState: { errors }, trigger, handleSubmit, reset, getValues, setValue, watch, resetField} = useForm();

    //Form State
    const [formData, setFormData] = useSetState({
        updateUserField : true
    })

    //Set user or item to edit values
    useEffect(() => {

        if(!formData.updateUserField)
            return;

        // set item data
        if(education !== null) {
            setValue("title", education.title)
            setValue("description", education.description)
        }

        setFormData({ updateUserField : false })

    },[formData.updateUserField])

    //form cancel
    const handleCancel = () => {
        resetField("title")
        resetField("description")
        onFormCancel()
    }

    //form submit
    const handleFormSubmit = async () => {

        const is_valid = await trigger()

        if(is_valid){
            const data = getValues()
            onFormSubmit(data)
        }

    }

    //update component on specialization prop change
    useDidUpdate(() => {
        setFormData({ updateUserField : true })
    },[education])

    return(
        <div className={"form"}>

            <div className={"form_row_wrapper type3"}>
                <label>Titre</label>
                <input type={"text"} name={"title"} id={"title"} defaultValue={""} {...register("title",{
                    required : "Veuillez renseigner cette information"
                })}/>
                <div className="error">{errors.title && errors.title.message}</div>
            </div>

            <div className={"form_row_wrapper type3"}>
                <label>Description</label>
                <textarea name={"description"} placeholder={'Année d\'obtention - Établissement'} {...register("description",{required : "Veuillez renseigner ce champ"})}></textarea>
                <div className="error">{errors.description && errors.description.message}</div>
            </div>

            <div className={"cta_wrapper text-right"}>
                <button className={"cta full blue"} onClick={handleSubmit(handleFormSubmit)}>
                    {education === null && <>Ajouter la formation</>}
                    {education !== null && <>Valider les modifications</>}
                </button>
                <a className={"cta full blue reverse"} onClick={handleCancel}>Annuler</a>
            </div>
        </div>
    )
}

FormEducation.defaultProps = {
    user: {},
    onFormSubmit : (data) => console.log("onFormSubmit is not defined -> ",data),
    handleCancel : () => console.log("handleCancel is not defined"),
    education : null
}

export default FormEducation