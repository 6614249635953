import React from "react";

const DataPrivacy = (props) => {

    return(
        <div id={"community_cgu_wrapper"}>
            <h1>Politique de confidentialité</h1>
            <p></p>
            <p>
                La présente politique de confidentialité a pour objectif d’informer les internautes et les utilisateurs des services proposés par EsterLaw sur les conditions de collecte et de traitement des données à caractère personnel mis en œuvre dans le cadre de nos activités. Elle s’applique au site web ou mobile www.esterlaw.io et à l’ application esterlaw (ci-après « le site »). Cette politique est amenée à évoluer et à être complétée, nous vous invitons à en prendre régulièrement connaissance. Soyez assurés que chez EsterLaw, nous veillons à la confidentialité de vos données, que nous collectons, traitons et conservons dans le respect du Règlement Général sur la Protection des données (UE) 2016/679 du 27 avril 2016 dit « RGPD » et de la loi n° 78-17 du 6 janvier 1978 relative à l’ Informatique, aux fichiers et aux libertés dite loi LIL.
            </p>
            <h2>1. Définitions :</h2>
            <p>
                Afin que cette politique soit parfaitement compréhensible pour vous, nous commençons par définir quelques termes :
                <br/>
                <br/>
                « Responsable de traitement » : le responsable d’un traitement de données à caractère personnel est, la personne, l’autorité publique, le service ou l’organisme qui détermine ses finalités et ses moyens.
                <br/>
                <br/>
                « Données à caractère personnel » : Il s’agit de toute information relative à une personne physique susceptible d’être identifiée de manière directe ou indirecte. Par exemple : un nom, une photo, une empreinte, une adresse postale, une adresse électronique, un numéro de téléphone, un numéro de sécurité sociale, un matricule interne, une adresse IP, un identifiant de connexion informatique, un enregistrement vocal, etc.
                <br/>
                <br/>
                « Nous » : désigne EsterLaw SAS.
                <br/>
                <br/>
                « Site » : désigne le site web ou mobile www.esterLaw.io et nos applications esterlaw IOS et Android.
                <br/>
                <br/>
                « Plateforme » désigne l’espace en ligne accessible depuis notre site web et/ ou nos applications IOS et Android.
                <br/>
                <br/>
                « Vous « : désigne les personnes dont nous sommes amenés à collecter et à traiter les données à caractère personnel : visiteurs du site, clients et utilisateurs du site et des services proposés, destinataires d’une opération de communication et /ou de prospection commerciale ;
                <br/>
                <br/>
                « Politique » : désigne le présent document informatif.
                <br/>
                <br/>
                « Utilisateur » : désigne le visiteur du site, tout internaute qui accède, navigue sur le site, remplit les formulaires de contacts et la personne qui s’inscrit et créé un compte utilisateur afin d’utiliser les services proposés.
                <br/>
                <br/>
                « Membre », désigne tout utilisateur avocat membre de la communauté « EsterLawyers » ;
                <br/>
                <br/>
                « Avocat » désigne toute personne physique habilitée à exercer la profession d’avocat en France, dans les conditions de la loi n° 71-1130 du 31 décembre 1971 modifiée ainsi que du décret n°91-1197 du 27 novembre 1991 et soumise aux règlements de la profession, ayant prêté serment d’avocat et régulièrement inscrite à un Barreau français et dont l’inscription n’est ni suspendue ni omise.
                <br/>
                <br/>
                « Traitement de données à caractère personnel » : toute opération, ou ensemble d’opérations, portant sur des données à caractère personnel, quel que soit le procédé utilisé (collecte, enregistrement, organisation, conservation, modification, extraction, consultation, utilisation, communication, transmission, rapprochement, archivage, destruction…).
                <br/>
                <br/>
                « Cookies » : suite d’informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Leurs usages sont variés et certains sont indispensables au bon fonctionnement du site.
                <br/>
                <br/>
                « Sous-traitants » désigne la personne physique ou morale (entreprise ou organisme public) qui traite des données pour le compte du responsable de traitement dans le cadre d’un service ou d’une prestation sur ses instructions et sous son contrôle.
            </p>
            <h2>2. Qui est le responsable de traitement ?</h2>
            <p>
                Le responsable de traitement est EsterLaw SAS dont le siège social situé 78 avenue des Champs Elysées 75008 Paris enregistrée au Registre du Commerce et des Sociétés de Paris sous le numéro 984 397 554.
                <br/><br/>
            </p>
            <h2>3. Pourquoi sont collectées et traitées vos données ?</h2>
            <p>
                Nous ne collectons que les informations vous concernant qui sont nécessaires et pertinentes au regard des objectifs légitimes liés aux services que nous vous proposons sur notre plateforme d’intermédiation.
                <br/>
                <br/>
                <ul>
                    <li>Recevoir et donner suite à vos demandes de contact ;</li>
                    <li>Recevoir et donner suite à vos demandes d’information ;</li>
                    <li>Permettre l’accès et l’utilisation d’EsterLaw par la création d’un compte utilisateur sur notre site ;</li>
                    <li>Gérer vos accès à votre compte sur notre site ;</li>
                    <li>Permettre la vérification, l’identification et l’authentification des données transmises par les utilisateurs ;</li>
                    <li>Délivrer les services, prestations ou produits disponibles sur notre plateforme (site ou application) ;</li>
                    <li>Permettre la proposition de mission par Matching et l’intermédiation ;</li>
                    <li>Permettre la communication entre nos membres utilisateurs ;</li>
                    <li>Gérer le suivi de la relation avec les utilisateurs dans le cadre de demandes d’informations, d’assistance, de réclamations ou de commentaires, adressés via notre site ou les réseaux sociaux ;</li>
                    <li>Permettre et gérer le paiement des services ;</li>
                    <li>Gérer la prospection commerciale et le marketing ;</li>
                    <li>Evaluer la satisfaction de nos clients ;</li>
                    <li>Réaliser des statistiques ;</li>
                    <li>Assurer le suivi de la relation commerciale avec nos fournisseurs, prestataires et partenaires ;</li>
                    <li>Gérer les abonnements à la newsletter ;</li>
                    <li>Gérer les parrainages ;</li>
                    <li>Gérer les consentements aux cookies et autres traceurs éventuels ;</li>
                    <li>Gérer les demandes d’exercice de droits ;</li>
                    <li>Permettre le fonctionnement et assurer la sécurité de notre site, notre plateforme et de notre système d’information et de communication ;</li>
                    <li>Permettre l’amélioration permanente de notre site de ses services et fonctionnalités ; Ces traitements sont fondés sur :</li>
                    <li>Exécution contractuelle ou précontractuelle : le traitement des données est nécessaire à l’exécution du contrat entre les utilisateurs et EsterLaw, lorsque vous créez un compte sur notre site et également dans le cadre de l’utilisation de la plateforme, de la fourniture des services, des prestations et des produits disponibles sur notre plateforme et leur paiement. Il est également nécessaire à l’exécution précontractuelle comme répondre aux questions ou demandes de renseignements de prospects ;</li>
                    <li>Intérêt légitime : EsterLaw peut disposer d’un intérêt légitime à traiter les données, par exemple dans le cadre de sa communication avec ses clients ou prospects professionnels, des traitements de prévention et de lutte contre la fraude, de sécurisation de ses supports numériques (sites internet, application etc.), de réalisation de statistiques ; Réponse aux demandes de renseignements ou de rendez-vous de Clients ou prospects : exécution contractuelle ou précontractuelle ;</li>
                    <li>Consentement : certains ne sont réalisés qu’avec votre consentement préalable qui peut être retiré à tout moment par exemple pour le rattachement de vos données de carte bancaire à votre compte EsterLaw, ou pour la pose de cookies non nécessaires au bon fonctionnement du site ;</li>
                </ul>
            </p>
            <h2>4. Quelles données sont collectées ?</h2>
            <p>
                Dans le cadre de nos activités et pour vous permettre d’utiliser notre plateforme, nous collectons et traitons des informations personnelles que vous nous communiquez et les métadonnées pour les finalités indiquées point 3 de la présente politique.
                <br/>
                <br/>
                Nous vous informons, lors de la collecte des données, si certaines doivent être obligatoirement renseignées ou si elles sont facultatives. Si vous ne fournissez pas les données obligatoires, vous ne pourrez pas accéder à l’ensemble des Services EsterLaw proposés au sein de notre plateforme.
                <br/>
                <br/>
                Certaines informations sont facultatives mais utiles à vous fournir un meilleur service et à vous permettre de tirer le meilleur parti de notre plateforme.
                <br/>
                <br/>
                EsterLaw est occasionnellement susceptible de traiter des données publiques collectées indirectement à son initiative.
                <br/>
                <br/>
                Données d’identification pour l’inscription: nom, prénom, adresse électronique professionnelle et numéro de mobile professionnel pour envoi d’un code de sécurité, mot de passe ;
                <br/>
                <br/>
                Profil des utilisateurs : vous choisissez librement les informations professionnelles que vous souhaitez renseigner telles que votre profession, fonction, expériences, diplômes, parcours, compétences, domaines et secteurs d’intervention, langues, certifications, formations, photo, coordonnées professionnelles , n° SIREN, SIRET, dénomination structure juridique, identité du représentant légal ; descriptions personnelles ou informations que vous fournissez dans votre profil.
                <br/>
                <br/>
                Utilisateurs avocats : Barreau, attestation de l’ordre, copie de la carte professionnelle, date de prestation de serment, spécialisations, tarifs indicatifs, disponibilités, préférences de missions, juridictions habituelles.
                <br/>
                <br/>
                Données que vous fournissez dans le cadre de demandes de missions ou de services : informations dans les offres de missions ou demandes d’aides.
                <br/>
                <br/>
                Informations dans vos réponses aux notifications ; informations que vous partagez avec d’autres membres telles que votre identité, votre profil, votre adresse et/ou votre localisation, Informations d’utilisation de la plateforme EsterLaw et de la communauté EsterLawyer : telles que les publications de missions ou demandes d’aides, les Matching et leurs suites, les dates et heures d’accès, les pages que vous avez consultées ou avec lesquelles vous avez interagi les liens sur lesquels vous cliquez pour accéder aux notifications ou à des applications tierces et les autres actions effectuées sur la plateforme ainsi que les métadonnées.
                <br/>
                <br/>
                Si vous choisissez d’établir un lien, de vous connecter ou d’accéder à la plateforme avec un service tiers, comme Google, Apple, vous demandez à ces services de nous envoyer des informations conformément aux modalités de contrôle de ces services ou à l’autorisation accordée par vous dans vos paramètres de confidentialité relatifs à ces services. Nous pouvons collecter des informations sur l’appareil utilisé tels que les événements et le type d’appareil. Données de communications : les communications de la messagerie instantanée sont chiffrées et ne sont pas lisibles par EsterLaw.
                <br/>
                <br/>
                Données de visioconférence : date heure.
                <br/>
                <br/>
                Données de paiement : Vos coordonnées bancaires ne transitent pas en clair sur les serveurs d’EsterLaw. Les paiements sont réalisés via Stripe plateforme de paiement sécurisée, conforme à la norme PCI-DSS, Les coordonnées de votre carte bancaire communiquées lors de votre paiement pour nos services ne transitent jamais en clair sur le réseau : elles sont chiffrées grâce au protocole TLS (Transport Layer Security).
                <br/>
                <br/>
                Autres informations : Informations que vous publiez dans l’espace d’échange avec la communauté réservée aux avocats : données de contributions, les commentaires, les interactions, les modèles d’actes ;
                <br/>
                <br/>
                Informations que vous communiquez à l’assistance EsterLaw ; Informations que vous nous fournissez dans le cadre d’enquêtes de satisfaction ou d’expériences clients pour améliorer nos services ;
                <br/>
                <br/>
                Données de connexion et cookies (adresse IP, logs de connexion) voir notre politique cookies ;
                <br/>
                <br/>
                Telles que l’adresse IP, les informations sur le matériel et le logiciel, les informations sur l’appareil, les informations sur les événements de l’appareil, les identifiants uniques, les données de plantage et les accusés de lecture. Le site et nos services ne sont pas destinés aux mineurs, nous ne collectons donc pas sciemment de données relatives à des mineurs. Ainsi vous devez être âgé d’au moins 18 ans pour nous communiquer vos données et créer un compte utilisateur.
            </p>
            <h2>5. Qui est destinataire de vos données ?</h2>
            <p>
                Les destinataires des données sont les personnes habilitées et déterminées au sein d’EsterLaw SAS et de prestataires, choisis pour leur expertise et leur fiabilité, dans la limite de leurs missions. Certaines des fonctionnalités et services mis à votre disposition dans notre plateforme nécessitent que nous transmettions des informations à d’autres utilisateurs (dans les conditions de nos CGV et de la charte de la communauté EsterLawyers). Les prestataires et sous-traitants sont limités et identifiés tels que notamment : OVH Prestataire d’hébergement et de sauvegarde https://www.ovhcloud.com/fr/personaldata-protection/ Stripe Prestataires de services de paiement prenant en charge les opérations de paiement et les contrôles appropriés. Stripe agit en qualité de sous-traitant er de responsable de traitement. Nous vous invitons consulter sa politique de confidentialité. https://stripe.com/fr/privacy Les transferts internationaux sont encadrés et conformes à la réglementation, ceux-ci sont soit effectués vers un pays assurant un niveau de protection équivalent à ceux exigés par la réglementation européenne, soit encadrés par des clauses contractuelles types (CCT) ou accords d’entreprises (BCR).
            </p>
            <h2>6. Combien de temps conservons-nous vos données ?</h2>
            <p>
                Conformément au règlement européen n ° 2016/679 relatif à la protection des données (RGPD) du 14 avril 2016, nous ne conservons les données vous concernant que durant la durée nécessaire à la réalisation des finalités ou à des titres probatoires pendant les durées légales. Plus précisément, les principales durées de conservation sont les suivantes : Données d’identification et de connexion d’utilisateurs, création d’un compte personnel vous permettant d’utiliser les services d’EsterLaw Durée d’activité du compte augmenté de 3 ans, puis archivage à titre conservatoire pendant la durée légale puis destruction. Données de profils et de missions contenus, contacts entre utilisateurs Durée d’activité du compte augmenté de 3 ans, puis archivage à titre conservatoire pendant la durée légale puis destruction. Commandes (abonnements) Durée d’activité du compte augmenté de 3 ans puis archivage à titre conservatoire pendant la durée légale puis destruction. Octroi d’avantages ou de remises 3 ans à compter de leur octroi. Communications 3 ans à compter de chaque communication, puis archivage à titre conservatoire pendant la durée légale puis destruction. Relations clients 3 ans à compter de chaque sollicitation question, réclamation puis archivage à titre conservatoire pendant la durée légale puis destruction. Enquêtes de satisfaction et recueil d’avis 3 ans à compter de l’avis ou de l’enquête puis archivage pendant la durée légale puis destruction. Statistiques 3 ans à compter de leur collecte puis archivage pendant la durée légale puis destruction. Mesures d’audience et autres cookies et traceurs 13 mois à compter du recueil de votre consentement. Prospection commerciale 3 ans après le dernier contact Logs 6 mois sur une période glissante.
            </p>
            <h2>7. Quels sont vos droits et comment exercer vos droits ?</h2>
            <p>
                Conformément au RGPD et à la loi informatique et libertés, les personnes qui utilisent notre site internet et ses fonctionnalités disposent d’un droit d’accès, de rectification de vos données personnelles, le droit d’en demander la suppression, de vous opposer à leur traitement, d’obtenir le cas échéant la limitation du traitement ou la portabilité. Vous pouvez à tout moment retirer votre consentement lorsque le traitement de vos données est conditionné par votre consentement. Vous disposez également du droit de nous donner des instructions post-mortem. Droit d’accès : À tout moment vous pouvez accéder aux données vous concernant directement dans votre compte utilisateur, vous pouvez aussi exercer ce droit auprès de nous. Droit de rectification : Vous pouvez directement mettre à jour, modifier ou supprimer des données vous concernant directement dans votre compte. Vous disposez également de la faculté de clôturer votre compte. Les données partagées avec d’autres utilisateurs peuvent rester actives et visibles même après la clôture de votre compte. Pour retirer votre consentement pour l’envoi de nos diffusions électroniques il vous suffit de vous désinscrire de la liste de diffusion par le lien contenu dans chacun de nos envois vous pouvez aussi paramétrer vos préférences de communications dans votre compte utilisateur. Pour exercer vos droits : contact rgpd Nous mettrons tout en œuvre pour vous répondre dans les plus brefs délais et au plus tard dans les délais légaux, nous ferons droit à vos demandes dans la mesure où cela est applicable et sous réserve de motifs impérieux légitimes dont pourrait justifier EsterLaw. Si vous estimez, après nous avoir contacté que vos droits n’ont pas été respecté vous disposez du droit de saisir la Commission Nationale de l’Informatique et des Libertés (CNIL), dont le site Internet peut être consulté à l’adresse suivante : www.cnil.fr.
            </p>
            <h2>8. Comment protégeons-nous vos données ?</h2>
            <p>
                EsterLaw met en œuvre des mesures techniques et organisationnelles appropriées afin de protéger vos données personnelles contre toute perte, destruction, accès ou divulgation non autorisée. Ces mesures peuvent notamment comprendre des mesures de chiffrement des données lors du transit et du stockage, de sauvegarde, d’authentification, de sécurisation des postes de travail et des supports de stockage, de sauvegardes, un encadrement des relations contractuelles.
            </p>
            <h2>9. Quelle est notre politique de gestion des cookies ?</h2>
            <p>
                Nous utilisons des cookies sur notre site web www.esterlaw.io notre application et au sein de nos communications. Votre consentement est collecté avant la pose de tous cookies non nécessaires. Pour avoir toutes les informations relatives à la gestion des cookies sur notre site internet, nous vous invitons à consulter notre Politique de cookies. Par ailleurs, notre site peut contenir des liens tiers vers des plateformes de médias sociaux gérés par des personnes ou organisations sur lesquels la société n’a aucun contrôle. A ce titre, nous ne sommes pas responsables de la façon dont vos Données seront stockées ou utilisées sur les serveurs de Tiers. Nous vous conseillons de prendre connaissance de leurs Politiques en matière de protection des Données personnelles pour chaque site Tiers
            </p>
            <br/>
            <br/>
            <div className={"wta_wrapper"}>
                <a className={"cta full blue"} onClick={props.handleClose}>Fermer</a>
            </div>
        </div>
    )
}

export default DataPrivacy