import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../userAuth/AuthContext";
import {useForm} from "react-hook-form";
import {emailPattern} from "../helper/formUtils";
import AppleSignin from 'react-apple-signin-auth';
import {CtaGoogleLogin} from "../other/Cta";
import PasswordInput from "../form/passwordInput";
import Api from "../helper/api";

const Signin = () => {

    //component state
    const [loginError, setLoginError] = useState("");
    const navigate = useNavigate()
    //user context
    const { login } = useContext(AuthContext);
    //handle form
    const {register,formState: { errors }, handleSubmit} = useForm();

    const api = new Api()

    /**
     * Classic credential login
     * @param data
     */
    const handleLogin = (data) => {
        setLoginError("")

        login(data.mail, data.password).catch((error) => {
            setLoginError('Identifiants inccorect')
            // setLoginError(error.response.data?.message)
        });
    }


    /**
     * Apple Login
     */
    const handleLoginApple = (data) => {
        const { authorization, user } = data
        console.log(authorization, 'APPLE INFO')
        console.log(user, 'USER INFO')

        const userData = {
            email: user.email,
            appleId: authorization.id_token,
            password: authorization.id_token,
            locale: 'fr',
            lastname: user.name.lastName,
            firstname: user.name.firstName
        }
        console.log(userData, 'USER DATA')

        api.checkIfExist(userData.appleId, "","")
            .then(()=> {
                login(userData.email, userData.password).catch((error) => {
                    setLoginError(error.response.data.message)
                });
            })
            .catch(() => {
                navigate(`/inscription_sso`, {state : {...userData}})
            })
    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <h1>Se connecter</h1>
                <form id={"connection_form"} onSubmit={handleSubmit(handleLogin)}>
                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"mail"}>Email</label>
                        <input type={"text"} name={"mail"} id={"mail"} placeholder={"Adresse mail"} {...register("mail", {
                            required: "Veuillez renseigner votre adresse e-mail",
                            pattern: {
                                value: emailPattern,
                                message: "Veuillez saisir une adresse e-mail valide"
                            }
                        })}/>
                        <div className={"error"}>{errors.mail && errors.mail.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"password"}>Mot de passe</label>
                        <PasswordInput name={"password"} id={"password"} placeholder={"Mot de passe"} register={register}/>
                        <div className={"error"}>{errors.password && errors.password.message}</div>
                    </div>

                    <div className={"connection_link text-right"}>
                        <Link to={"/forgot-password"}>Mot de passe oublié ?</Link>
                    </div>

                    {loginError && <div className={"error text-center"}>Identifiants incorrect</div>}

                    <div className={"cta_wrapper"}>
                        <input className={"cta blue full"} type={"submit"} value={"Se connecter"}/>
                    </div>
                </form>

                <div className={"cta_wrapper"}>
                    <CtaGoogleLogin onLogin={(data) => handleLogin(data)} />
                    {/*{<AppleSignin*/}
                    {/*        authOptions={{*/}
                    {/*            clientId: 'com.esterlaw.web',*/}
                    {/*            scope: 'name email',*/}
                    {/*            nonce: 'nonce',*/}
                    {/*            usePopup: true,*/}
                    {/*            redirectURI: "https://pro-staging.esterlaw.io/inscription_sso"*/}
                    {/*        }}*/}
                    {/*        className={"cta full social_login apple"}*/}
                    {/*        noDefaultStyle={false}*/}
                    {/*        onSuccess={handleLoginApple}*/}
                    {/*        onError={(error) => console.error(error, 'APPLE ERROR')}*/}
                    {/*    >*/}
                    {/*        <span>Continuer avec Apple</span>*/}
                    {/*    </AppleSignin>}*/}
                </div>

                <div className={"separator"}>
                    <div/>
                    <span>Ou</span>
                    <div/>
                </div>

                <div className={"connection_link"}>
                    Vous n’êtes pas encore inscrit ? <Link className={"connection_small_link"} to={"/inscription"}>S'inscrire</Link>
                </div>
            </div>
        </div>
    )
}

export default Signin